<template>
  <div>
    <div class="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main class="flex-grow">
        <div id="">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">
            <div class="max-w-3xl mx-auto text-center pb-12">
              <h1 class="h1 text-black">FAQ</h1>
            </div>
            <div class="faq content">
              <h3 class="">What is BotBro?</h3>
              <p>
                BotBro is an application that enables you to set up your own
                bots to auto purchase products or notify you when they are back in stock.
              </p>
            </div>
            <div class="faq content">
              <h3 class="">What kind of bots can I make with it?</h3>
              <p>
                You can create a notification bot for any website. Auto checkout features are only supported by Target bots, Walmart bots, and Amazon bots. With more supported websites being added in the near future.
              </p>
            </div>
            <div class="faq content">
              <h3 class="">How much does it cost to use?</h3>
              <p>
                A monthly fee of $15.00 for unlimited bots and use. Your
                subscription can be managed from your account page.
              </p>
            </div>
            <div class="faq content">
              <h3 class="">I'm having an issue...</h3>
              <p>
                Reach out to us via our contact page and you should hear back from us within a few hours.
              </p>
            </div>
            <div class="faq content">
              <h3 class="">How do I get started?</h3>
              <p>
                1. Download the application and make an account. 2. Sign in and create bots. 3. Sit back and relax while your bots do the work!
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // Children can override the title.
    title: "FAQ | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "Frequently asked questions about BotBro",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/faq" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "FAQ | BotBro" },
      {
        itemprop: "description",
        content: "Frequently asked questions about BotBro.",
      },
    ],
  },
  components: {},
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
}

.subtitle {
  text-align: left;
}

.container {
  text-align: center;
}

.card-meetup-link {
  color: black;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.card {
  margin: 10px; //padding: 10px;
  padding-bottom: 70px;
}

#FAQContainer {
}

.faq {
  padding: 30px;
  margin: 30px;
  margin-top: 0px;
  background-color: transparentize(lightgrey, 0.7);
}
</style>
