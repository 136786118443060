<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <main class="flex-grow">
      <div id="ThankYouContainer">
        <div class="">
           <div class="pt-32 pb-12 md:pt-40 md:pb-20">
            <div class="max-w-3xl mx-auto text-center pb-12">
              <h1 class="h1 text-black">Thank you!</h1>
              <div class="content my-4">
                You can now sign in to the BotBro application and begin creating bots.
              </div>
            </div>
           </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  methods: {
      scrollElementIntoView(element) {
          document.getElementById(element).scrollIntoView(true)
      },
      registerWindowsDownload() {
        this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/peuVCMj7svkCEKGZj5MD'})
        this.$gtag.event(['WindowsDownload'], {
        'event_category': 'engagement',
      })
      },
      registerMacDownload() {
        this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/QSWmCL79svkCEKGZj5MD'})
        this.$gtag.event(['MacDownload'], {
        'event_category': 'engagement',
      })
      }
  },
  metaInfo: {
    // Children can override the title.
    title: "Thank you! | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Thank you for using BotBro!",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/thankyou" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Thank You | BotBro" },
      {
        itemprop: "description",
        content:
          "Thank you for using BotBro",
      },
      // {
      //   itemprop: "image",
      //   content:
      //     "https://www.usedteslas.io/img/white-tesla-snowy.904395c1.jpeg",
      // },
    ],
  },
  components: {},
  mounted() {
    this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/SKFpCIOhsPkCEKGZj5MD'})
    this.$gtag.event(['Subscribe'], {
      'event_category': 'ecommerce',
      'event_value': 10
    })
  }
}
</script>

<style scoped lang="scss">
// #ThankYouContainer {
//   // margin-top: 75px;
// }

.content {
    padding:10px;
    text-align: center;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: left;
}

.container {
  text-align: center;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.card {
  margin: 10px;
  padding: 10px;
  padding-bottom: 70px;
}
</style>
