<template>
  <div v-if="isAuthResolved && isLocationResolved" id="app">
    <!-- <Header /> -->
      <!-- <BurgerNavbar></BurgerNavbar> -->
      <router-view :key="$route.path" />
      <!-- <vue-progress-bar></vue-progress-bar> -->
      <!-- <TheFooter /> -->
      <Footer />
  </div>
  <!-- <div id="app">
    <router-view />
  </div> -->
</template>

<script>
const jqueryScript = document.createElement("script");
jqueryScript.setAttribute(
  "src",
  "//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"
);
document.head.appendChild(jqueryScript);
import Footer from "@/components/cruip/partials/Footer.vue";
//import TheFooter from "@/components/shared/TheFooter";
import { mapGetters } from "vuex";
//import Header from '@/components/cruip/partials/Header.vue'
//import BurgerNavbar from "@/components/shared/BurgerNavbar";
import AOS from "aos";
import Sticky from 'sticky-js'
import { focusHandling } from 'cruip-js-toolkit'

//import Lingallery from 'lingallery';
//Vue.component('lingallery', Lingallery);
//import Slider from '@/components/shared/Slider'

export default {
  name: "app",
  components: {
    Footer
    //Header
    //BurgerNavbar,
    //'slider': Slider
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
    },
    ...mapGetters({
      user: "auth/authUser",
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
  },
  mounted() {
    this.$Progress.finish();
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky("[data-sticky]");
    focusHandling();
    // Route change
    if (this.$router) {
      this.$watch("$route", () => {
        // eslint-disable-next-line no-unused-vars
        const sticky = new Sticky("[data-sticky]");
        focusHandling("outline");
      });
    }
  },
  created() {
    this.$store.dispatch("meta/fetchMetaData");

    // PROGRESS BAR
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish();
    });
    // PROGRESS BAR END
  },
};
</script>
<style lang="scss">
@import "assets/css/spacing.css";
@import "assets/css/stripeconnect.css";
@import "~bulma/bulma.sass";
@import "assets/fontawesome/css/all.css";
@import 'assets/css/cruip/style.scss';

#logo {
  width: 500px;
  margin-right: 10px;
  display: inline;
  transform: translate(-40px, 25px);
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.page-wrapper {
  min-height: 55vh;
}

.bold {
  font-weight: bold;
}

.hero {
  position: relative;
}

.hero-body {
  padding: 3rem 1.5rem;
}

.hero-bg {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(0, 0, 0, 0.3)
    ),
    url("./assets/herobackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

#main-logo {
  height: auto;
  width: 140px;
  position: relative;
  top: 50%;
  transform: translate(0, 20%);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #main-logo {
    height: auto;
    width: 130px;
    position: relative;
    top: 50%;
    transform: translate(0, 10%);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .hero-bg {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(0, 0, 0, 0.3)
      ),
      url("./assets/herobackground.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
  }
}

.button.is-primary {
  background-color: #2acb98 !important;
}

.button.is-secondary {
  background-color: #456990 !important;
}

.button.is-danger {
  background-color: #f45b69 !important;
}

.button.is-warning {
  background-color: #c47ac0 !important;
}

input.input.is-primary {
  border-color: #456990 !important;
}

input.input.is-danger {
  border-color: #f45b69 !important;
}

textarea.textarea.is-primary {
  border-color: #456990 !important;
}

textarea.textarea.is-danger {
  border-color: #f45b69 !important;
}

.navbar-brand {
  margin-bottom: 40px;
}

// Nav styling
.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.navbar-item {
  transition: 0.3s;
}

.burger-bars {
  position: fixed;
  top: 12px;
  right: 35px;
  z-index: 20;
  &:hover {
    width: 40px;
    height: 40px;
  }
}

.circleLoader,
.circleLoader:before,
.circleLoader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.circleLoader {
  color: #2acb98;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.circleLoader:before,
.circleLoader:after {
  content: "";
  position: absolute;
  top: 0;
}
.circleLoader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.circleLoader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

//////

.barLoader,
.barLoader:before,
.barLoader:after {
  background: #2acb98;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.barLoader {
  color: #2acb98;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.barLoader:before,
.barLoader:after {
  position: absolute;
  top: 0;
  content: "";
}
.barLoader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.barLoader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
