<template>
  <footer>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <!-- Top area: Blocks -->
      <div
        class="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200"
      >
        <!-- 1st block -->
        <div class="sm:col-span-12 lg:col-span-3">
          <div class="mb-2">
            <!-- Logo -->
            <router-link :to="'/'" class="logo" href="/">
              <!-- <div class="logo">
              <div class="title is-4">usedteslas</div>
              <div class="title" id="title-dot">.</div>
              <div class="title is-4">io</div>
            </div> -->
            <div id="footerlogocontainer">
              <div id="main-logo"><img id="logofooter" src="../../../assets/botbro/transparent Mascot.png"></div>
              <div id="logoTextContainer">
                <h1 id="logoText1">Bot</h1>
                <!-- <h1 id="logoText2">-web-</h1> -->
                <h1 id="logoText3">Bro</h1>
              </div>
            </div>
            </router-link>
          </div>
          <div class="text-sm text-gray-600">
            <a
              href="https://getterms.io/view/xHMhI/tos/en-us"
              class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >Terms</a
            >
            ·
            <a
              href="https://getterms.io/view/xHMhI/privacy/en-us"
              class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >Privacy Policy</a
            >
            ·
            <a
              href="https://getterms.io/view/xHMhI/cookie/en-us"
              class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >Cookie Policy</a
            >
          </div>
        </div>

        <!-- 2nd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-800 font-medium mb-2">Account</h6>
          <ul class="text-sm">
            <div v-if="user">
              <li class="mb-2">
                <router-link
                  to="account"
                  class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >Manage Account</router-link
                >
              </li>
              <!-- <li class="mb-2">
                <router-link
                  to="store"
                  class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >Your store</router-link
                >
              </li> -->
            </div>
            <div v-else>
              <li class="mb-2"></li>
              <li class="mb-2">
                <router-link
                  to="register"
                  class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >Sign up</router-link
                >
              </li>
              <li class="mb-2">
                <router-link
                  to="login"
                  class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >Login</router-link
                >
              </li>
            </div>
          </ul>
        </div>

        <!-- 3rd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-800 font-medium mb-2">Resources</h6>
          <ul class="text-sm">
            <!-- <li class="mb-2">
              <router-link
                to="home"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Home</router-link
              >
            </li> -->
            <li class="mb-2">
              <router-link
                to="blog"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Blog</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="features"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Features</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="contact"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Contact</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="docs"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Documentation</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="affiliates"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Affiliate Program</router-link
              >
            </li>
          </ul>
        </div>

        <!-- 4th block -->
        <!-- <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-800 font-medium mb-2">Company</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <router-link
                to="#"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Home</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="#"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >About us</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="#"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Company values</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="#"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Pricing</router-link
              >
            </li>
            <li class="mb-2">
              <router-link
                to="#"
                class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                >Privacy Policy</router-link
              >
            </li>
          </ul>
        </div> -->

        <!-- 5th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 class="text-gray-800 font-medium mb-2">Subscribe</h6>
          <p class="text-sm text-gray-600 mb-4">
            Get all the latest news on BotBro delivered directly to your
            inbox.
          </p>
          <form>
            <div class="flex flex-wrap mb-4">
              <div class="w-full">
                <label class="block text-sm sr-only" for="newsletter"
                  >Email</label
                >
                <div class="relative flex items-center max-w-xs">
                  <input
                    id="newsletter"
                    type="email"
                    v-model="subscribeForm.email"
                    class="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm"
                    placeholder="Your email"
                    required
                  />
                  <button
                    type="button"
                    id="subscribe"
                    class="absolute inset-0 left-auto"
                    @click="subscribe()"
                    aria-label="Subscribe"
                  >
                    <span
                      class="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300"
                      aria-hidden="true"
                    ></span>
                    <svg
                      class="w-3 h-3 fill-current text-primary mx-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                        fill-rule="nonzero"
                      />
                    </svg>
                  </button>
                </div>
                <!-- Success message -->
                <!-- <p class="mt-2 text-green-600 text-sm">Thanks for subscribing!</p> -->
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Bottom area -->
      <div
        class="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200"
      >
        <!-- Social links -->
        <ul class="flex mb-4 md:order-1 md:ml-4 md:mb-0">
          <!-- <li>
            <a
              :href="'https://instagram.com/botbro.io'"
              target="_blank"
              class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Instagram"
            >
              <svg
                class="w-8 h-8 fill-current"
                viewBox="0 0 46 46"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.76 29.03v-7.373h-1.537c.152.48.23.975.23 1.49 0 .958-.243 1.838-.73 2.647-.485.807-1.146 1.447-1.98 1.918-.834.47-1.744.705-2.73.705-1.495 0-2.773-.514-3.835-1.543-1.062-1.027-1.593-2.27-1.593-3.726 0-.517.076-1.013.228-1.49H16.21v7.373c0 .2.065.37.2.5.13.14.296.2.494.2H29.07c.188 0 .352-.06.488-.2s.202-.3.202-.49zm-3.233-6.064c0-.94-.343-1.743-1.03-2.406-.686-.664-1.515-.996-2.486-.996-.96 0-1.78.332-2.47.996-.68.663-1.03 1.466-1.03 2.406 0 .942.35 1.743 1.03 2.407s1.51.996 2.48.996c.975 0 1.8-.34 2.49-1s1.03-1.47 1.03-2.41zm3.233-4.097v-1.88c0-.22-.076-.4-.23-.56-.15-.158-.336-.235-.556-.235h-1.98c-.22 0-.406.08-.558.233-.15.155-.228.34-.228.552v1.876c0 .22.076.404.228.556s.337.23.558.23h1.98c.22 0 .406-.078.557-.23.16-.15.23-.338.23-.558zm1.98-2.37v12.99c0 .61-.22 1.14-.66 1.58-.44.44-.967.66-1.582.66H16.502c-.614 0-1.142-.22-1.582-.66-.44-.44-.66-.97-.66-1.586V16.5c0-.614.22-1.142.66-1.582.44-.44.967-.66 1.582-.66h12.996c.615 0 1.14.22 1.582.66.44.44.66.967.66 1.58z"
                />
              </svg>
            </a>
          </li> -->

          <!-- <li class="ml-4">
            <a
              :href="'https://facebook.com/botbro.io'"
              target="_blank"
              class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Facebook"
            >
              <svg
                class="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"
                />
              </svg>
            </a>
          </li> -->

          <li class="ml-4">
            <a
              :href="'https://github.com/Colin-Moran'"
              target="_blank"
              class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Github"
            >
              <svg
                class="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z"
                />
              </svg>
            </a>
          </li>
        </ul>

        <!-- Copyrights note -->
        <div class="text-sm text-gray-600 mr-4">
          &copy; 2021 BotBro. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    isFormInvalid() {
      return this.$v.subscribeForm.$invalid;
    },
  },
  data() {
    return {
      subscribeForm: {
        email: null,
      },
    };
  },
  validations: {
    subscribeForm: {
      email: {
        required,
      },
    },
  },
  methods: {
    subscribe: function () {
      //window.fbq('track', 'ContactFormSubmitted');
      const params = [this.subscribeForm];
      this.$store
        .dispatch("auth/subscribe", params)
        .then(() => {
          this.subscribeForm.email = "";
          this.$toasted.success("Subscribed!", { duration: 1000 });
          return new Promise(function (resolve) {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        })
        .catch((err) => {
          console.log(err);
          this.subscribeForm.email = "";
          this.$toasted.error("Oops, something bad happened...", {
            duration: 1000,
          });
          return new Promise(function (resolve) {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        });
    },
  },
};
</script>
<style lang="scss">

#main-logo {
  display: inline;
}

#footerlogocontainer {
  margin-left: 30px;
  transform: translate(30px, 0px);
}

#logofooter {
  width: 50px;
  margin-right: 0px;
  display: inline;
  transform: translate(-60px, 25px);
}

#logoTextContainer {
  //background-color: #02809050;
  border-radius: 5px;
  height: 100%;
}

#logoText1 {
  color: #000000;
  display: inline;
  font-weight: bold;
  font-size: 20px;
}

#logoText2 {
  color: #f45b69;
  display: inline;
  font-weight: bold;
  font-size: 20px;
}

#logoText3 {
  color: #2acb98;
  display: inline;
  font-weight: bold;
  font-size: 20px;
}
</style>