import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import PageHome from '@/pages/PageHome'
import PageLogin from '@/pages/PageLogin'
import PageRegister from '@/pages/PageRegister'
// import PageProfile from '@/pages/PageProfileFree'
import PageAccount from '@/pages/PageAccount'
//import PageOnboardRefresh from '@/pages/PageOnboardRefresh'
//import PageOnboardReturn from '@/pages/PageOnboardReturn'

import PageAbout from '@/pages/PageAbout'
import PageThankYou from '@/pages/PageThankYou'
import PageFAQ from '@/pages/PageFAQ'
import PageContact from '@/pages/PageContact'
import PageForgotPassword from '@/pages/PageForgotPassword'
import PageBlog from '@/pages/PageBlog'
//import PageBlogPost from '@/pages/PageBlogPost'
import PageDocumentation from '@/pages/PageDocumentation'
import PageTutorials from '@/pages/PageTutorials'
import PageResetPassword from '@/pages/PageResetPassword'
import PageBlogPostTargetAutoPurchaseBot from '@/pages/PageBlogPostTargetAutoPurchaseBot'
import PageBlogPostWalmartAutoPurchaseBot from '@/pages/PageBlogPostWalmartAutoPurchaseBot'
import PageBlogPostAmazonAutoPurchaseBot from '@/pages/PageBlogPostAmazonAutoPurchaseBot'
import PageAffiliates from '@/pages/PageAffiliates'
import PageFeatures from '@/pages/PageFeatures'
import PageTargetBotFeature from '@/pages/PageTargetBotFeature'
import PageWalmartBotFeature from '@/pages/PageWalmartBotFeature'
import PageNotificationBotFeature from '@/pages/PageNotificationBotFeature'
import PageAmazonBotFeature from '@/pages/PageAmazonBotFeature'
// import PagePrivacyPolicy from '@/pages/PagePrivacyPolicy'



//import PageListingDetail from '@/pages/PageListingDetail'
//import PageNotFound from '@/pages/PageNotFound'
import PageNotAuthenticated from '@/pages/PageNotAuthenticated'

Vue.use(Router)

const router = new Router({
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'PageHome',
      component: PageHome
    },
    // {
    //   path: '/me',
    //   name: 'PageProfile',
    //   component: PageProfile,
    //   meta: {onlyAuthUser: true}
    // },

    {
      path: '/login',
      name: 'PageLogin',
      component: PageLogin,
      meta: { onlyGuestUser: true }
    },
    {
      path: '/register',
      name: 'PageRegister',
      component: PageRegister,
      meta: { onlyGuestUser: true }
    },
    {
      path: '/401',
      name: 'PageNotAuthenticated',
      component: PageNotAuthenticated
    },

    {
      path: '/contact',
      name: 'PageContact',
      component: PageContact,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/forgot',
      name: 'PageForgotPassword',
      component: PageForgotPassword,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/reset',
      name: 'PageResetPassword',
      component: PageResetPassword,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/about',
      name: 'PageAbout',
      component: PageAbout,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/faq',
      name: 'PageFAQ',
      component: PageFAQ,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/account',
      name: 'PageAccount',
      component: PageAccount,
      meta: {onlyAuthUser: true}
    },
    // {
    //   path: '/onboard-user/refresh',
    //   name: 'PageDashboard',
    //   component: PageDashboard,
    //   meta: {onlyAuthUser: true}
    // },
    // {
    //   path: '/onboard-user/return',
    //   name: 'PageDashboard',
    //   component: PageDashboard,
    //   meta: {onlyAuthUser: true}
    // },
    {
      path: '/thankyou',
      name: 'PageThankYou',
      component: PageThankYou
    },
    {
      path: '/blog',
      name: 'PageBlog',
      component: PageBlog,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/documentation',
      name: 'PageDocumentation',
      component: PageDocumentation,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/tutorials',
      name: 'PageTutorials',
      component: PageTutorials,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/blog/target-bot',
      name: 'PageBlogPostTargetAutoPurchaseBot',
      component: PageBlogPostTargetAutoPurchaseBot,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/blog/target-auto-purchase-bot',
      name: 'PageBlogPostTargetAutoPurchaseBot',
      component: PageBlogPostTargetAutoPurchaseBot,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/blog/walmart-bot',
      name: 'PageBlogPostWalmartAutoPurchaseBot',
      component: PageBlogPostWalmartAutoPurchaseBot,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/blog/amazon-bot',
      name: 'PageBlogPostAmazonAutoPurchaseBot',
      component: PageBlogPostAmazonAutoPurchaseBot,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/docs',
      name: 'PageDocumentation',
      component: PageDocumentation,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/affiliates',
      name: 'PageAffiliates',
      component: PageAffiliates,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/features',
      name: 'PageFeatures',
      component: PageFeatures,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/features/target-bot',
      name: 'PageTargetBotFeature',
      component: PageTargetBotFeature,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/features/walmart-bot',
      name: 'PageTargetBotFeature',
      component: PageWalmartBotFeature,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/features/notification-bot',
      name: 'PageNotificationBotFeature',
      component: PageNotificationBotFeature,
      meta: {onlyAuthUser: false}
    },
    {
      path: '/features/amazon-bot',
      name: 'PageAmazonBotFeature',
      component: PageAmazonBotFeature,
      meta: {onlyAuthUser: false}
    }
    // {
    //   path: '/privacy',
    //   name: 'PagePrivacyPolicy',
    //   component: PagePrivacyPolicy,
    //   meta: {onlyAuthUser: false}
    // }
    // {
    //   path: '/faq',
    //   name: 'PageFAQ',
    //   component: PageFAQ,
    //   meta: {onlyAuthUser: false}
    // },

    // {
    //   path: '/:username',
    //   name: 'PageUserProfile',
    //   component: PageUserProfile,
    //   meta: {onlyAuthUser: false}
    // },
    // {
    //   path: '/listings/:url',
    //   name: 'PageListingDetail',
    //   component: PageListingDetail,
    //   meta: {onlyAuthUser: false}
    // },
  ],
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  store.dispatch('auth/getAuthUser')
    .then(() => {
      const isAuthenticated = store.getters['auth/isAuthenticated']

      if (to.meta.onlyAuthUser) {
        if (isAuthenticated) {
          next()
        } else {
          next({name: 'PageLogin'})
        }
      } else if (to.meta.onlyGuestUser) {
        if (isAuthenticated) {
          next({name: 'PageHome'})
        } else {
          next()
        }
      } else {
        next()
      }
    })
})

export default router
