<template>
  <section class="relative">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">

        <!-- Page header -->
        <div class="max-w-4xl pb-12 md:pb-20 text-center md:text-left">
          <h1 class="h1 text-black">BotBro Features & Supported Sites</h1>
          <blockquote class="text-gray-600 italic mb-6">
            A single subscription to BotBro includes ALL features and supported websites.
          </blockquote>
        </div>

        <!-- Section tags -->
        <!-- <div class="border-b border-gray-300 pb-4 mb-12">
          <ul class="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
            <li class="mx-5 my-1">
              <a class="text-blue-600" href="#0">All</a>
            </li>
            <li class="mx-5 my-1">
              <a class="text-gray-800 hover:underline" href="#0">Tutorials</a>
            </li>
            <li class="mx-5 my-1">
              <a class="text-gray-800 hover:underline" href="#0">Tips & Tricks</a>
            </li>
            <li class="mx-5 my-1">
              <a class="text-gray-800 hover:underline" href="#0">Free ebooks</a>
            </li>
            <li class="mx-5 my-1">
              <a class="text-gray-800 hover:underline" href="#0">Guides</a>
            </li>
          </ul>
        </div> -->

        <!-- Articles list -->
        <div class="max-w-sm mx-auto md:max-w-none">

          <!-- Articles container -->
          <div class="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">

            <!-- 1st article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out">
              <header>
                <router-link to="/features/target-bot" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/assets/cruip/images/targetlogo.png')" width="352" height="198" alt="Target Logo" />
                  </figure>
                </router-link>
                <!-- <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Guides</a>
                    </li>
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-blue-100 hover:bg-blue-200 transition duration-150 ease-in-out" href="#0">Intermediate</a>
                    </li>
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white shadow-sm">4 min read</span>
                    </li>
                  </ul>
                </div> -->
                <h3 class="text-xl text-black font-bold leading-snug tracking-tight mb-2">
                  <router-link to="/features/target-bot" class="hover:underline">Target Bot</router-link>
                </h3>
              </header>
              <p class="text-gray-600 flex-grow">Monitor and automatically purchase products from Target with options for local pickup and quantity. RedCard exclusives are supported.</p>
              <!-- <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/assets/cruip/images/news-author-01.jpg')" width="32" height="32" alt="Author 01" />
                  </a>
                  <a class="relative -ml-2" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/assets/cruip/images/news-author-03.jpg')" width="32" height="32" alt="Author 03" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">By </span>
                  <a class="font-medium hover:underline" href="#0">Lisa Allison</a> & <a class="font-medium hover:underline" href="#0">Justin Park</a>
                </div>
              </footer> -->
            </article>

            <!-- 2nd article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
              <header>
                <router-link to="/features/walmart-bot" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/assets/cruip/images/walmartlogo.png')" width="352" height="198" alt="Walmart Logo" />
                  </figure>
                </router-link>
                <!-- <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Tutorials</a>
                    </li>
                  </ul>
                </div> -->
                <h3 class="text-xl text-black font-bold leading-snug tracking-tight mb-2">
                  <router-link to="/features/walmart-bot" class="hover:underline">Walmart Bot</router-link>
                </h3>
              </header>
              <p class="text-gray-600 flex-grow">Check product stock and auto-checkout items from Walmart as soon as they restock.</p>
              <!-- <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/assets/cruip/images/news-author-02.jpg')" width="32" height="32" alt="Author 02" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">By </span>
                  <a class="font-medium hover:underline" href="#0">Knut Mayer</a>
                </div>
              </footer> -->
            </article>

            <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
              <header>
                <router-link to="/features/amazon-bot" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/assets/cruip/images/amazonlogo.png')" width="352" height="198" alt="Amazon Logo" />
                  </figure>
                </router-link>
                <!-- <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Tutorials</a>
                    </li>
                  </ul>
                </div> -->
                <h3 class="text-xl text-black font-bold leading-snug tracking-tight mb-2">
                  <router-link to="/features/amazon-bot" class="hover:underline">Amazon Bot</router-link>
                </h3>
              </header>
              <p class="text-gray-600 flex-grow">Keep track of a product pages and automatically buy items when they become available. You can specify the quantity to checkout and a maximum price per unit.</p>
              <!-- <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/assets/cruip/images/news-author-02.jpg')" width="32" height="32" alt="Author 02" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">By </span>
                  <a class="font-medium hover:underline" href="#0">Knut Mayer</a>
                </div>
              </footer> -->
            </article>

            <!-- 3rd article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
              <header>
                <router-link to="/features/notification-bot" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/assets/cruip/images/notificationlogo.png')" width="352" height="198" alt="News 03" />
                  </figure>
                </router-link>
                <!-- <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Guides</a>
                    </li>
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-blue-100 hover:bg-blue-200 transition duration-150 ease-in-out" href="#0">Intermediate</a>
                    </li>
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white shadow-sm">7 min read</span>
                    </li>
                  </ul>
                </div> -->
                <h3 class="text-xl text-black font-bold leading-snug tracking-tight mb-2">
                  <router-link to="/features/notification-bot" class="hover:underline">Notification Bot</router-link>
                </h3>
              </header>
              <p class="text-gray-600 flex-grow">Create notification bots for any website that detect an element or text and recieve notifications as soon as the page includes that element. Perfect for product stock alerts.</p>
              <!-- <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/assets/cruip/images/news-author-01.jpg')" width="32" height="32" alt="Author 01" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">By </span>
                  <a class="font-medium hover:underline" href="#0">Lisa Allison</a>
                </div>
              </footer> -->
            </article>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesList',
  metaInfo: {
    // Children can override the title.
    title: "Features & Supported Sites | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "A list of all the currently supported features and sites that BotBro has to offer. A single subscription to BotBro includes ALL features and supported websites. Target, Walmart, Amazon, restock notifications.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/features" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Features & Supported Sites | BotBro" },
      {
        itemprop: "description",
        content: "A list of all the currently supported features and sites that BotBro has to offer. A single subscription to BotBro includes ALL features and supported websites. Target, Walmart, Amazon, restock notifications.",
      },
    ],
  },
}

</script>