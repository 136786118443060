<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 pt-12">
      <div class="">

        <!-- CTA box -->
        <div class="bg-primary rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" data-aos="zoom-y-out">

          <div class="flex flex-col lg:flex-row justify-between items-center">

            <!-- CTA content -->
            <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
              <h3 class="h3 text-white mb-2">Ready to get started?</h3>
              <p class="text-white text-lg opacity-100">Sign up takes less than a minute.</p>
            </div>

            <!-- CTA button -->
            <div>
              <a class="btn text-black bg-white" href="https://botbro.getrewardful.com/signup">Sign up</a>
            </div>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AffiliateCta',
}
</script>