<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <BlogList />

    </main>

  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'
import BlogList from '../components/cruip/partials/BlogList.vue'

export default {
  name: 'Blog',
  components: {
    Header,
    BlogList
  },
  metaInfo: {
    // Children can override the title.
    title: "Blog | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "Find the latest updates from BotBro and tutorials to show you how to better use the product.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/blog" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Blog | BotBro" },
      {
        itemprop: "description",
        content: "Find the latest updates from BotBro and tutorials to show you how to better use the product.",
      },
    ],
  },
}
</script>
