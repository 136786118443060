<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <div class="max-w-3xl mx-auto lg:max-w-none"> -->
      <div class="max-w-6xl mx-auto px-4 sm:px-6">

        <!-- Page content -->
        <div class="pt-24 sm:pt-32 max-w-6xl mx-auto flex flex-col lg:flex-row lg:space-x-10 xl:space-x-10">

          <!-- Content -->
          <div class="w-12/12 sm:w-8/12">
            <header class="mb-4">
              <!-- Title -->
              <h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-2">Target Bot</h1>
              <p></p>
            </header>

            <!-- Meta -->
            <div class="space-y-3 sm:flex sm:items-center sm:justify-between sm:space-y-0 mb-0">
              <!-- Right side -->
              <div class="flex flex-wrap items-center sm:justify-end space-x-4">
                <!-- Tag -->
                <!-- <div class="inline-flex items-center text-xs font-medium text-gray-100 bg-gray-900 bg-opacity-60 rounded-full text-center px-2 py-0.5">
                  <svg class="w-3 h-3 shrink-0 fill-current text-yellow-500 mr-1" viewBox="0 0 12 12">
                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                  </svg>
                  <span>Special Offer</span>
                </div> -->
                <!-- Rating -->
                <!-- <div class="flex items-center space-x-2 mr-2">
                  <div class="flex space-x-1">
                    <button>
                      <span class="sr-only">1 star</span>
                      <svg class="w-4 h-4 fill-current text-yellow-500" viewBox="0 0 16 16">
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                    </button>
                    <button>
                      <span class="sr-only">2 stars</span>
                      <svg class="w-4 h-4 fill-current text-yellow-500" viewBox="0 0 16 16">
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                    </button>
                    <button>
                      <span class="sr-only">3 stars</span>
                      <svg class="w-4 h-4 fill-current text-yellow-500" viewBox="0 0 16 16">
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                    </button>
                    <button>
                      <span class="sr-only">4 stars</span>
                      <svg class="w-4 h-4 fill-current text-yellow-500" viewBox="0 0 16 16">
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                    </button>
                    <button>
                      <span class="sr-only">5 stars</span>
                      <svg class="w-4 h-4 fill-current text-gray-300" viewBox="0 0 16 16">
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                    </button>
                  </div>
                  <div class="inline-flex text-sm font-medium text-yellow-600">4.2</div>
                </div> -->
              </div>
            </div>

            <!-- Image -->
            <figure class="mb-6">
              <div class="relative pb-9/16">
                <!-- <iframe class="absolute w-full h-full" src="https://player.vimeo.com/video/572321748" title="Video" allowFullScreen></iframe> -->
                <iframe class="absolute w-full h-full" src="https://www.youtube.com/embed/hg-7oMEisiE" title="Video" allowFullScreen></iframe>
              </div>
              <!-- <img class="w-full border-2 rounded-sm" :src="require('@/assets/cruip/images/target-bot-complete.png')" width="640" height="360" alt="Target Bot Feature" /> -->
            </figure>

            <!-- Product content -->
            <div>
              <h2 class="text-xl leading-snug text-gray-800 font-bold mb-2">Overview</h2>
              <p class="mb-6 text-black">The Target Auto Purchase feature allows you to create a bot that can monitor a product page on Target.com and checkout when the product is available.</p>
              <p class="mb-6 text-black">Several options are available to further customize your bot.</p>
              <ul class="list-disc list-inside space-y-1 mb-6 text-black">
                <li>Refresh Rate: How often the bot should check the page</li>
                <li>Local Pickup: Order the product for local pickup or delivery</li>
                <li>Stay Signed In: Stay signed in while checking product stock - useful for RedCard exclusives</li>
                <li>Quantity: How many of the product should the bot try to purchase</li>
              </ul>
              <blockquote class="text-gray-800 italic mb-6">
                A subscription to BotBro includes ALL features and supported websites, not just the one discussed on this page.
              </blockquote>
            </div>

            <hr class="my-6 border-t border-gray-200" />



          </div>

          <!-- Sidebar -->
          <div class="w-12/12 sm:w-4/12">
              <h1 class="text-2xl md:text-3xl text-gray-800 font-bold mb-4">Get BotBro</h1>
            <div class="bg-white p-5 shadow-lg rounded-sm border-2 border-gray-300 lg:w-72 xl:w-80">
              <!-- <div class="text-sm text-gray-800 font-semibold mb-3">Select a Package</div> -->
              <ul class="space-y-2 sm:flex sm:space-y-0 sm:space-x-2 lg:space-y-2 lg:space-x-0 lg:flex-col mb-4">
                <li>
                  <button class="w-full h-full text-left py-3 px-4 rounded bg-white border-2 border-primary hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                    <div class="flex flex-wrap items-center justify-between mb-0.5">
                      <span class="font-semibold text-gray-800">Monthly</span>
                      <span class="font-medium text-green-600">$15.00</span>
                    </div>
                    <div class="text-sm text-black">A low monthly fee for unlimited access.</div>
                  </button>
                </li>
                <li>
                  <button class="w-full h-full text-left py-3 px-4 rounded bg-gray-200 border-2 border-gray-200 shadow-sm duration-150 ease-in-out" disabled>
                    <div class="flex flex-wrap items-center justify-between mb-0.5">
                      <!-- Best Value ✨ -->
                      <span class="font-semibold text-gray-800">Yearly <span class="text-xs italic text-primary align-top"> Coming soon</span></span>
                      <span class="font-medium text-green-600"></span>
                    </div>
                    <div class="text-sm text-black">A yearly fee at a much lower cost per month.</div>
                  </button>
                </li>
                <li>
                  <button class="w-full h-full text-left py-3 px-4 rounded bg-gray-200 border-2 border-gray-200 shadow-sm duration-150 ease-in-out" disabled>
                    <div class="flex flex-wrap items-center justify-between mb-0.5">
                      <span class="font-semibold text-gray-800">Lifetime <span class="text-xs italic text-primary align-top"> Coming soon</span></span>
                      <span class="font-medium text-green-600"></span>
                    </div>
                    <div class="text-sm text-black">A one-time fee for unlimited lifetime use.</div>
                  </button>
                </li>
              </ul>
              <div class="mb-4">
                <a class="btn w-full bg-black text-white hover:bg-primary hover:text-white" href="/register">Buy Now - $15.00</a>
              </div>
              <div class="text-xs text-gray-500 italic text-center">Subscriptions can be canceled at anytime via your account dashboard. <a class="underline hover:no-underline" href="https://getterms.io/view/xHMhI/tos/en-us">Terms</a>.</div>
            </div>
            <div class="mt-4 bg-white p-5">
                <h1 class="text-lg text-black mb-4">Click <a class="text-primary" href="/blog/target-bot">here</a> to read our tutorial on how to set up a Target Auto Purchase Bot</h1>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'

export default {
  name: 'PageTargetBotFeature',
  components: {
    Header
  },
  metaInfo: {
    // Children can override the title.
    title: "Target Bot Feature | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "The Target Auto Purchase feature allows you to create a bot that can monitor a product page on Target.com and checkout when the product is available. Works with RedCard exclusives.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/features/target-bot" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Target Bot Feature | BotBro" },
      {
        itemprop: "description",
        content: "The Target Auto Purchase feature allows you to create a bot that can monitor a product page on Target.com and checkout when the product is available. Works with RedCard exclusives.",
      },
    ],
  },
}
</script>