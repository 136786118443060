<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-16 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <!-- <form class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <label class="block text-sm sr-only" for="search">Search</label>
                        <div class="relative flex items-center">
                          <input id="search" type="search" class="form-input w-full text-gray-800 px-3 py-2 pl-10" placeholder="Search the docs" />
                          <button type="submit" class="absolute inset-0 right-auto" aria-label="Search">
                            <svg class="w-4 h-4 fill-current text-gray-400 mx-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form> -->

                  <!-- <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden" @click="sidebarNavOpen = !sidebarNavOpen">
                    <span>Docs navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out" :class="{'rotate-180': sidebarNavOpen}" />
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" :class="{'rotate-180': sidebarNavOpen}" />
                    </svg>
                  </button> -->

                  <!-- Docs nav -->
                  <nav class="md:block" :class="{ 'hidden' : !sidebarNavOpen }">
                    <ul class="font-medium -my-2">
                      <!-- 1st level -->
                      <li class="py-2">
                        <a class="flex items-center hover:underline" @click.prevent="sidebarLinkOpen = !sidebarLinkOpen" aria-haspopup="true" :aria-expanded="sidebarLinkOpen">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-primary mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 3.294L1.4 1.035C1.1.847.7.941.4 1.13c-.2.189-.4.471-.4.753v10.353c0 .377.2.753.6.847L7 15.718V3.294zM15.6 1.13c-.3-.189-.6-.189-.9-.095L9 3.295v12.423l6.4-2.542c.4-.188.6-.47.6-.847V1.882c0-.282-.2-.564-.4-.753z" />
                            </svg>
                            <span>Introduction</span>
                          </div>
                          <svg class="w-3 h-3 fill-current text-gray-400 cursor-pointer ml-1 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 4.305L5.989 8.598 1.695 4.305A1 1 0 00.28 5.72l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z" />
                          </svg>
                        </a>
                        <!-- 2nd level -->
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" @click="scrollElementIntoView('installation')">Installation</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" @click="scrollElementIntoView('quick')">Quick start</a>
                          </li>
                        </ul>
                      </li>
                      <!-- <li class="py-2">
                        <a class="flex items-center hover:underline" href="#introduction">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-primary mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
                            </svg>
                            <span>Components</span>
                          </div>
                        </a>
                      </li>
                      <li class="py-2">
                        <a class="flex items-center hover:underline" href="#introduction">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-primary mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 9H1a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v7a1 1 0 01-1 1zM6 16H1a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1zM15 6h-5a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v4a1 1 0 01-1 1zM15 16h-5a1 1 0 01-1-1V9a1 1 0 011-1h5a1 1 0 011 1v6a1 1 0 01-1 1z" />
                            </svg>
                            <span>Page layouts</span>
                          </div>
                        </a>
                      </li> -->
                  
                      <li class="py-2">
                        <a class="flex items-center hover:underline" href="#introduction">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-primary mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 15.414l-2.5-3V1a1 1 0 011-1h3a1 1 0 011 1v11.414l-2.5 3zM7 0H1C.4 0 0 .4 0 1v2h3v2H0v2h3v2H0v2h3v2H0v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z" />
                            </svg>
                            <span @click="scrollElementIntoView('tutorials')">Tutorials</span>
                          </div>
                        </a>
                      </li>
                      <li class="py-2" >
                        <a class="flex items-center hover:underline">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-primary mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="4.5" cy="4.5" r="4.5" />
                              <circle cx="12" cy="13" r="3" />
                              <circle cx="14.5" cy="5.5" r="1.5" />
                            </svg>
                            <span @click="scrollElementIntoView('how')">How does it work?</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </nav>

                </div>
              </aside>

              <!-- Page container -->
              <DocumentationContent />

            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <!-- <Footer /> -->

  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'
import DocumentationContent from '../components/cruip/partials/DocumentationContent.vue'
// import Footer from '../components/cruip/partials/Footer.vue'

export default {
  name: 'Documentation',
  components: {
    Header,
    DocumentationContent
    // Footer,
  },
  data: function () {
    return {
      sidebarNavOpen: false,
      sidebarLinkOpen: true
    }
  },
  methods: {
      scrollElementIntoView(element) {
          document.getElementById(element).scrollIntoView(true)
      },
      registerFeedback(grade) {
        this.$gtag.event(['documentation_feedback_' + grade], {
        'event_category': 'engagement',
        })
        this.$toasted.success("Thanks for your feedback!", { duration: 1000 });
      }
  },
  metaInfo: {
    // Children can override the title.
    title: "Documentation | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "BotBro is an application that can be used to create custom bots. You can create bots that monitor product pages and alert you when the item is in stock. You can also create bots that will automatically order the product when it is in stock.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/docs" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Documentation | BotBro" },
      {
        itemprop: "description",
                content: "BotBro is an application that can be used to create custom bots. You can create bots that monitor product pages and alert you when the item is in stock. You can also create bots that will automatically order the product when it is in stock.",
      },
    ],
  }, 
}
</script>
