<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
    <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <div class="max-w-3xl mx-auto lg:max-w-none">

          <article>

            <!-- Article header -->
            <header class="max-w-3xl mx-auto mb-20">
              <!-- Title -->
              <h1 class="h1 text-center mb-4 text-black">Auto Checkout Amazon Bot</h1>
            </header>

            <!-- Article content -->
            <div class="lg:flex lg:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                  <h4 class="text-lg font-bold leading-snug tracking-tight mb-4 text-black">Table of contents</h4>
                  <ul class="font-medium -my-1">
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('introduction')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Introduction</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('installation')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Installing BotBro</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('configuration')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Configuring Settings</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('creating')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Creating an Amazon Buying Bot</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('running')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>How to use</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>

              <!-- Main content -->
              <div>

                <!-- Article meta -->
                <div class="flex items-center mb-6">
                  <div class="flex flex-shrink-0 mr-3">
                    <a class="relative" href="#0">
                      <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                      <img class="relative rounded-full" :src="require('@/assets/cruip/images/colin-moran.jpg')" width="32" height="32" alt="Colin Moran" />
                    </a>
                  </div>
                  <div>
                    <span class="text-gray-600">By </span>
                    <a class="font-medium hover:underline" href="https://www.linkedin.com/in/colin-p-moran/" target="_blank">Colin Moran</a>
                    <span class="text-gray-600"> · April 27, 2022</span>
                  </div>
                </div>
                <hr class="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                <!-- Article body -->
                <div class="text-lg text-gray-600">
                  <p id="introduction" class="mb-8" style="scroll-margin-top: 100px;">
                    In this post we'll be showing you how to use BotBro to create an Amazon buying bot that can monitor a product page and automatically add to cart and checkout when the product becomes available. You can also set a maximum price to avoid buying an item that is being resold for more than the MSRP.
                  </p>
                  <p class="mb-8">
                    As and example, we'll be creating a bot that will automatically buy a Nvidia 3080 Ti GPU when it is in stock and below our maximum price. Let's begin by installing the BotBro application.
                  </p>
                  <figure class="mb-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/amazon-bot-complete.png')" width="768" height="432" alt="BotBro Application" />
                  </figure>
                  <h3 id="installation" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Download BotBro</h3>
                  <p class="mb-8">
                    BotBro is an application that gives you the ability to easily create auto buying bots, and other types of bots, with a few clicks. Start by downloading the application by clicking either the MacOS or Windows download link.
                  </p>
                  <div class="columns">
                    <div class="column">
                      <a id="" @click="registerWindowsDownload()" style="width: 300px; display: block; text-align: center;" class="top-full bg-primary rounded-full font-medium group p-4 shadow-lg is-hoverable text-white" aria-controls="modal" href="https://botbro.s3.amazonaws.com/BotBro+Setup+0.0.1.exe" download>
                        <span class="ml-3">Windows Download<i class="fa fa-download"></i></span>
                      </a>
                    </div>
                    <div class="">
                      <a id="" @click="registerMacDownload()" style="width: 300px; display: block; text-align: center;" class="top-full bg-primary rounded-full font-medium group p-4 m-4 shadow-lg is-hoverable text-white" aria-controls="modal" href="https://botbro.s3.amazonaws.com/BotBro-0.0.1.pkg" download>
                        <span class="ml-3">macOS Download<i class="fa fa-download"></i></span>
                      </a>
                    </div>
                  </div>
                  <p class="mb-8">
                  </p>
                  <p class="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                    IMPORTANT: When you run the installer your firewall will probably ask if you are sure you want to open the file.<br><br> For Windows: Click "More Info" and then "Run Anyways" <br><br>For Mac: Open System Preferences, then go to the Security & Privacy tab. Towards the bottom you will see a button that says "Open Anyway."
                  </p>
                  <p class="mb-8">
                    Before you are able to sign in and use the application you will need to
                    <router-link to="/register">
                      <a class="inline-flex items-center text-base text-blue-600 font-medium hover:underline">
                        <span>create an account</span>
                      </a>
                    </router-link>
                    and sign up.
                  </p>
                  <p class="mb-8">
                    Once you have created your account and signed up for a subscription you can sign into the application and start creating bots.
                  </p>
                  <h3 id="configuration" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Configuring Settings</h3>
                  <p class="mb-8">After signing in, the first thing we need to do is fill out a few settings.</p>
                  <p class="mb-8">The first type of settings we will look at will determine what notifications we will recieve about our Amazon bot. Click the "Notification Settings" button.</p>
                  <figure class="mb-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/notification-settings.png')" width="768" height="432" alt="BotBro notification settings" />
                  </figure>
                  <p class="mb-8">When your Amazon bot succeeds in buying an item it will send you a notification letting you know that the product has been purchased. You can recieve email and text notifications to the email and phone number you provide. Notifications are optional, so you can leave any box unchecked if you do not want to recieve notifications of that type. Make sure you click save after you're done making your changes.</p>
                  <p class="mb-8">After that, the next thing that we will look at is our "Auto Purchase" settings.</p>
                  <figure class="mb-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/amazon-auto-purchase-settings.png')" width="768" height="432" alt="BotBro Amazon bot auto purchase settings" />
                  </figure>
                  <p class="mb-8">Input your email and password for the Amazon account that the bot should use to make the purchase.</p>
                  <p class="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                    NOTE: All sensitive information is stored locally on your computer, we do not have access to any of it.
                  </p>
                  <h3 id="creating" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Amazon Bot Creation</h3>
                  <p>
                    Now that all that is out of the way, let's create an auto buying bot for Amazon! All you need to do is fill out all of the fields and click "Add". You can create an unlimited amount of bots. Here is a brief summary on what all the different fields mean.
                  </p>
                  <ul class="list-disc list-inside mb-8 mt-4">
                    <li class="mb-1">
                      <span class="">Bot Type - This is what type of bot you want to make, for this tutorial select "Auto Purchase."</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Name - What do you want the bot to be called.</span>
                    </li>
                    <li class="mb-1">
                      <span class="">URL - Copy the URL from the product page that you want the bot to monitor.</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Refresh Rate - This is how often (in seconds) you want the bot to check the page. 15 seconds is a good number, the faster the page refreshes the more likely it is that Amazon will get suspicious and start showing captchas.</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Website - Since every website has a different checkout process, this tells the bot what website it is checking out on. Make sure it is set to "Amazon"</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Quantity - Use this to set the quantity of items to purchase. Make sure it does not exceed the amount that Amazon allows for that particular product.</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Max Price - The maximum price that the bot will purchase the item for in USD. If this is set to 19.99 the bot will not purchase anything for more than $19.99</span>
                    </li>
                  </ul>
                  <figure class="mb-8 mt-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/amazon-bot-settings.png')" width="768" height="432" alt="Amazon bot settings" />
                  </figure>
                  <p class="mb-8">As soon as you have all the settings filled out properly, you can just click the "Add" button to create your bot. If all of your settings are valid you will see your new Amazon buy bot pop up on the left side of the application.</p>
                  <div>
                    <h3 id="running" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Running the Bot</h3>
                    <p class="mb-8">Now all you have to do is click the play button on your bot to start running it. You should see a web browser open up that goes to the Amazon product page that you entered.</p>
                    <figure class="mb-8 mt-8">
                      <img class="w-full rounded" :src="require('@/assets/cruip/images/amazon-bot-complete.png')" width="768" height="432" alt="BotBro Amazon bot running" />
                    </figure>
                    <p class="mb-8">The bot will keep checking the page until the item is in stock and below the maximum price. When those conditions are met it will complete the checkout and send you notifications in accordance with your notification settings. The bot will stop running after the order is placed. You can also click the stop button on your bot at any point to stop it.</p>
                  </div>
                  <div>
                    <hr class="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                    <div class="mt-8">
                      If you're interested in learning about what else you can do with BotBro, check out some of our other blog posts.
                    </div>
                    <div class="mt-6">
                      <router-link to="/blog" class="inline-flex items-center text-base text-primary font-medium hover:underline">
                        <svg class="w-3 h-3 fill-current text-primary flex-shrink-0 mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                        </svg>
                        <span>Back to the blog</span>
                      </router-link>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <!-- Article footer -->
          </article>

        </div>

      </div>
    </div>
  </section>

    </main>
  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'
export default {
  name: 'PageBlogPostAmazonAutoPurchaseBot',
  methods: {
      scrollElementIntoView(element) {
          document.getElementById(element).scrollIntoView(true)
      },
      registerWindowsDownload() {
        this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/peuVCMj7svkCEKGZj5MD'})
        this.$gtag.event(['WindowsDownload'], {
        'event_category': 'engagement',
      })
      },
      registerMacDownload() {
        this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/QSWmCL79svkCEKGZj5MD'})
        this.$gtag.event(['MacDownload'], {
        'event_category': 'engagement',
      })
      }
  },
  metaInfo: {
    // Children can override the title.
    title: "Auto Checkout Amazon Bot | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "How to use BotBro to create an Amazon buying bot that can monitor a product page and automatically add to cart and checkout when the product becomes available. Set a maximum price to avoid buying an item that is being resold for more than the MSRP.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/blog/amazon-bot" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Auto Checkout Amazon Bot | BotBro" },
      {
        itemprop: "description",
        content: "How to use BotBro to create an Amazon buying bot that can monitor a product page and automatically add to cart and checkout when the product becomes available. Set a maximum price to avoid buying an item that is being resold for more than the MSRP.",
      },
    ],
  },
  components: {
    Header
  },
}
</script>
