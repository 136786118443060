<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
      <!-- Page sections -->
      <FeaturesList />
      <Newsletter />
    </main>
  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'
import FeaturesList from '../components/cruip/partials/FeaturesList.vue'
import Newsletter from '../components/cruip/partials/Newsletter.vue'

export default {
  name: 'Tutorials',
  components: {
    Header,
    FeaturesList,
    Newsletter
 },
}
</script>
