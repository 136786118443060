<template>
  <div>
    <div class="flex flex-col min-h-screen overflow-hidden">
      <!-- Site header -->
      <Header />
      <!-- Page content -->
      <main class="flex-grow">
        <!-- Contact section -->
        <section>
          <div class="max-w-6xl mx-auto px-4 sm:px-6">
            <div class="pt-32 md:pt-40">
              <!-- Section header -->
              <div class="text-center pb-12 md:pb-16">
                <h1 class="h1 text-black mb-4">
                  BotBro Family
                </h1>
                <p class="text-xl text-gray-600" data-aos="zoom-y-out">Provide value to your audience, access exclusive opportunities, and generate monthly passive income through our affiliate program. Join the BotBro Family.</p>
                <AffiliateTable />
                <AffiliateCta />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
// import PricingTables from "../components/cruip/partials/PricingTables.vue";
import AffiliateTable from "../components/cruip/partials/AffiliateTable.vue";
import AffiliateCta from "../components/cruip/partials/AffiliateCta.vue";

export default {
  name: 'Affiliates',
  components: {
    AffiliateTable,
    AffiliateCta
  },
  metaInfo: {
    // Children can override the title.
    title: "Become an Affiliate | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "Learn about our affiliate program! Lifetime commissions for every customer that you refer. Join the BotBro Family." },
      {
        link: [
          { rel: "canonical", href: "https://www.botbro.io/affiliates" },
        ],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Become an Affiliate | BotBro" },
      { itemprop: "description", content: "Learn about our affiliate program! Lifetime commissions for every customer that you refer. Join the BotBro Family." },
    ],
  },
};
</script>

<style scoped lang="scss">
#AffiliateContainer {
  padding: 10px;
  margin-top: 75px;
  margin-bottom: 40px;
  text-align: center; //padding: 10px;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: left;
}

.container {
  text-align: center;
}

.card-meetup-link {
  color: black;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.card {
  margin: 10px;
  margin-top: 10px; //padding: 10px;
  padding-bottom: 70px;
}
</style>