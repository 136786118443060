<template>
    <div>
        <div class="flex flex-col min-h-screen overflow-hidden">
            <!-- Site header -->
            <Header />
            <!-- Page content -->
            <main class="flex-grow">
                <!-- Contact section -->
                <section>
                    <div class="max-w-6xl mx-auto px-4 sm:px-6">
                        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
                            <!-- Section header -->
                            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h1 class="h1">Let's get you a new password.</h1>
                            </div>

                            <!-- Contact form -->
                            <form class="max-w-xl mx-auto">
                                <div class="flex flex-wrap -mx-3 mb-4">
                                    <div class="w-full px-3">
                                        <label class="block text-gray-800 text-sm font-medium mb-1" for="password">Password <span class="text-red-600">*</span></label
                                    >
                                    <input
                                      v-model="resetForm.password"
                                      id="password"
                                      type="password"
                                      class="form-input w-full text-gray-800"
                                      placeholder="New Password"
                                      required
                                    />
                                  </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-4">
                                  <div class="w-full px-3">
                                    <label
                                      class="block text-gray-800 text-sm font-medium mb-1"
                                      for="confirmpassword"
                                      >Confirm Password
                                      <span class="text-red-600">*</span></label
                                    >
                                    <input
                                      v-model="resetForm.confirmPassword"
                                      id="confirmpassword"
                                      type="password"
                                      class="form-input w-full text-gray-800"
                                      placeholder="Confirm Password"
                                      required
                                    />
                                  </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mt-4">
                                  <div class="w-full px-3">
                                    <button
                                      type="button"
                                      :disabled="isFormInvalid"
                                      @click="resetPassword()"
                                      class="btn text-white bg-primary hover:bg-secondary w-full"
                                    >
                                      Reset Password
                                    </button>
                                  </div>
                                </div>
                                <!-- <div class="text-sm text-gray-600 mt-4">
                                  By clicking "send" you consent to allow botbro.io to store and
                                  process the personal information submitted above and agree to
                                  our <a class="underline" href="">terms and conditions</a> as
                                  well as our <a class="underline" href="">Privacy Policy</a>.
                                </div> -->
                              </form>
                            </div>
                          </div>
                        </section>
                      </main>
                    </div>
                  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
    metaInfo: {
        // Children can override the title.
        title: "Reset Password | BotBrot",
        // Result: My Page Title ← My Site
        // If a child changes the title to "My Other Page Title",
        // it will become: My Other Page Title ← My Site
        //titleTemplate: '%s ← usedteslas.io ',
        // Define meta tags here.
        meta: [
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            {
                name: "description",
                content: "Reset your password.",
            },
            {
                link: [{ rel: "canonical", href: "https://www.botbro.io/reset" }],
            },
            // Google / Schema.org markup:
            { itemprop: "name", content: "Reset Password | BotBro" },
            {
                itemprop: "description",
                content: "Reset your password.",
            },
            // {
            //     itemprop: "image",
            //     content: "https://www.usedteslas.io/img/white-tesla-snowy.904395c1.jpeg",
            // },
        ],
    },
    components: {},
    data() {
        return {
            resetForm: {
                password: null,
                confirmPassword: null,
                token: null
            },
        };
    },
    validations: {
        resetForm: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    computed: {
        isFormInvalid() {
            return this.$v.resetForm.$invalid;
        },
    },
    methods: {
        resetPassword: function() {
            //window.fbq('track', 'ContactFormSubmitted');
            const url = new URL(window.location.href);
            const token = url.searchParams.get("token");
            this.resetForm.token = token;
            const params = [this.resetForm];

            this.$store
                .dispatch("auth/resetPassword", params)
                .then(() => {
                    this.$toasted.success("Password reset!", { duration: 1000 });
                    return new Promise(function(resolve) {
                        setTimeout(() => {
                            resolve();
                        }, 2000);
                    });
                })
                .then(() => this.$router.push('/login'))
                .catch((errorMessage) => {
                    this.$toasted.error(errorMessage.message, { duration: 5000 });
                });
        },
    },
};
</script>

<style scoped lang="scss">
#ContactContainer {
    padding: 10px;
    margin-top: 75px;
    margin-bottom: 40px;
    text-align: center; //padding: 10px;
}

#sendButtonContainer {
    text-align: left;
    padding: 0px;
    margin: 0px;
}

#contact-seller {
    margin: 0px;
    width: 100%;
}

.inputContainer {
    padding-bottom: 10px;
}

.title {
    text-align: center;
}

.subtitle {
    text-align: left;
}

.container {
    text-align: center;
}

.card-meetup-link {
    color: black;
}

.is-hoverable {
    transition: 0.3s;
    &:hover {
        -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
        -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
        box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    }
}

.card {
    margin: 10px;
    margin-top: 10px; //padding: 10px;
    padding-bottom: 70px;
}
</style>