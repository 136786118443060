<template>
  <main>
    <!-- <AppHero/> -->
    <div id="AboutContainer">
      <div class="card is-hoverable large">
          <div class="card-content">
            <p class="title">About</p>
          </div>
          <div class="content">
          </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  components: {
  },
  metaInfo: {
    // Children can override the title.
    title: "About | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "Sign in to manage your BotBro account." },
      {
        link: [
          { rel: "canonical", href: "https://www.botbro.io/login" },
        ],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Login | BotBro" },
      { itemprop: "description", content: "Sign in to manage your BotBro account." },
    ],
  },
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
}
.subtitle {
  text-align: left;
}
.container {
  text-align: center;
}

  .card-meetup-link {
    color: black;
  }

  .is-hoverable {
    transition: 0.3s;

    &:hover {
      -webkit-box-shadow: 2px 4px 15px -2px rgba(189,189,189,1);
      -moz-box-shadow: 2px 4px 15px -2px rgba(189,189,189,1);
      box-shadow: 2px 4px 15px -2px rgba(189,189,189,1);
    }
  }

  .card {
    margin: 10px;
    padding: 10px;
    padding-bottom: 70px;
  }

  #AboutContainer {
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
  padding: 10px;
}
</style>
