<template>
<div>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <main class="flex-grow">
      <div id="loginContainer">
        <div class="container has-text-centered">
          <div class="column is-4 is-offset-4">
            <div class="pt-32 pb-12 md:pt-40 md:pb-20">
              <div class="max-w-3xl mx-auto text-center pb-12">
                  <h1 class="h1 text-black">
                      Login
                  </h1>
              </div>
              <div class="box">
                <form>
                  <div class="field">
                    <div class="control">
                      <input
                        v-model="form.email"
                        @blur="$v.form.email.$touch()"
                        class="input is-medium transition"
                        type="email"
                        placeholder="Email or Username"
                        autofocus=""
                        autocomplete="email"
                      />
                      <div v-if="$v.form.email.$error" class="form-error">
                        <span
                          v-if="!$v.form.email.required"
                          class="help is-danger"
                          >Email or username is required</span
                        >
                        <!-- <span v-if="!$v.form.email.email" class="help is-danger">Email address is not valid</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input
                        v-model="form.password"
                        @blur="$v.form.password.$touch()"
                        class="input is-medium transition"
                        type="password"
                        placeholder="Your Password"
                        autocomplete="current-password"
                      />
                      <div v-if="$v.form.password.$error" class="form-error">
                        <span
                          v-if="!$v.form.password.required"
                          class="help is-danger"
                          >Password is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <button
                    @click.prevent="login"

                    class="button bg-primary text-white is-hoverable is-large is-fullwidth"
                  >
                    Login
                  </button>
                </form>
              </div>
              <p class="has-text-grey">
                <router-link :to="{ name: 'PageRegister' }"
                  >Create Account</router-link
                > |
                <router-link :to="{ name: 'PageForgotPassword' }"
                  >Forgot password?
                  </router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  metaInfo: {
    // Children can override the title.
    title: "Login | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "Sign in to manage your BotBro account." },
      {
        link: [
          { rel: "canonical", href: "https://www.botbro.io/login" },
        ],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Login | BotBro" },
      { itemprop: "description", content: "Sign in to manage your BotBro account." },
    ],
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    login() {
      this.$v.form.$touch();
      this.$store
        .dispatch("auth/loginWithEmailAndPassword", this.form)
        .then(() => this.$router.push("/account"))
        .catch((errorMessage) => {
          this.$toasted.error(errorMessage, { duration: 5000 });
        });
    },
  },
};
</script>

<style scoped lang="scss">
#loginContainer {
  // margin-top: 75px;
}

.container {
  width: 100%;
}

.hero.is-success {
  background: #ffff;
}

.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.hero-body {
  padding: 0px;
  justify-content: center;
}

// .box {
//   //margin-top: 5rem;
// }

.avatarBox {
  width: 100%;
  text-align: center;
}

.avatarDiv {
  width: 70%;
  margin: auto;
}

.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}

.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;
}

p.subtitle {
  padding-top: 1rem;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

button {
  margin: 0px;
}

.transition {
  transition: 0.3s;
}
</style>
