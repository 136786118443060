
import Vue from 'vue'
import App from './App.vue'
import './assets/css/cruip/style.scss'
import router from './router'
import VueHead from 'vue-head'
import store from './store'
import vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'
import VueProgressBar from 'vue-progressbar'
import Meta from 'vue-meta';
import VueGtag from "vue-gtag";
//import sitemapMiddleware from './router/sitemapMiddleware'
import AppSpinner from './components/shared/AppSpinner'
import VueCurrencyInput from 'vue-currency-input'
import Fragment from 'vue-fragment'
import Header from './components/cruip/partials/Header.vue'
const stripeJs = document.createElement("script");
stripeJs.src = "https://js.stripe.com/v3/";
stripeJs.async = true;
stripeJs.onload = () => {
  setTimeout(() => {
    // Vue.prototype.$stripe = window.Stripe("pk_test_51IvT0KC55A3G2XJrmYeUfz4msyEhksrB4LHP6Wp3neOtrPkT1jmMhhayIngJXpCjN0UHMKy0qNsBsHsW9Ft3K4Zr00KQmaR72Y");
    Vue.prototype.$stripe = window.Stripe("pk_live_51IvT0KC55A3G2XJrnVrmed1DqlywPRC7rWjnZvPSfLCEs1LzuZ4zEd9IicCwwAtbWLEWLnsS764HXLe5VpMrFrik002DHWBZJx");
  }, 500);
};


(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
const rewardfulJs = document.createElement("script");
rewardfulJs.setAttribute('data-rewardful', '4cec37');
rewardfulJs.src = "https://r.wdfl.co/rw.js"; // data-rewardful='4cec37'
rewardfulJs.async = true;
rewardfulJs.onload = () => {
  setTimeout(() => {
    // Vue.prototype.$stripe = window.Stripe("pk_test_51IvT0KC55A3G2XJrmYeUfz4msyEhksrB4LHP6Wp3neOtrPkT1jmMhhayIngJXpCjN0UHMKy0qNsBsHsW9Ft3K4Zr00KQmaR72Y");
    const referral = window.Rewardful.referral;
    // console.log(referral)
    Vue.prototype.$referral = referral;
  }, 500);
};
document.body && document.body.appendChild(stripeJs);
document.head && document.head.appendChild(rewardfulJs);
Vue.use(Fragment.Plugin)
// import VueFacebookPixel from 'vue-analytics-facebook-pixel'

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'USD', 'distraction-free': true }
}
const progressBarOptions = {
  color: '#01D1B2',
  failedColor: '#FF3860',
  thickness: '3px',
  transition: {
    speed: '0.5s',
    opacity: '0.2s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueHead)
Vue.use(Meta);
// Vue.use(VueGtag, {
//   config: { id: "AW-845401249" }
// }, router);
// Google Analytics
Vue.use(VueGtag, {
  config: { id: "AW-845401249" },
  includes: [
    { id: 'G-ZEEBKZZ9E6' }
  ]
}, router);
Vue.use(VueProgressBar, progressBarOptions)
Vue.use(VueCurrencyInput, pluginOptions)
//App.get('/sitemap.xml', sitemapMiddleware());

// Vue.use(VueFacebookPixel)
/**
 * Init facebook tracking pixel
 * @param  {String} appId
 * @param  {object} [data={}]
 */
// Vue.analytics.fbq.init('230582218287165', {
//   em: 'info@usedteslas.io'
// })
Vue.config.productionTip = false

Vue.component('AppSpinner', AppSpinner)
Vue.component('Header', Header)
Vue.use(vuelidate)
Vue.use(Toasted)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuelidate,
  render: h => h(App),
}).$mount('#app')

// export default new Vue({ // export the Vue instance
//   ...App
// }).$mount('#app')






