<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-0 md:py-0 border-t border-gray-200">

        <!-- Items -->
        <div class="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-4 items-start md:max-w-2xl lg:max-w-none">

          <div class="absolute top-1/2 w-full h-1 bg-gradient-to-r from-primary to-primary hidden lg:block" aria-hidden="true"></div>

          <!-- 1st item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-primary text-white font-bold mb-3">1</div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">Sign Up</h4>
            <p class="text-gray-600 text-center">Sign up and download the application. Install the application and log in.</p>
          </div>

          <!-- 2nd item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-primary text-white font-bold mb-3">2</div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">Set Up Notifications</h4>
            <p class="text-gray-600 text-center">Specify how you want to receive notifications (SMS, email, etc).</p>
          </div>

          <!-- 3rd item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-primary text-white font-bold mb-3">3</div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">Create Bots</h4>
            <p class="text-gray-600 text-center">Create bots for product pages by entering the URL and what to track.</p>
          </div>

          <!-- 4th item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-primary text-white font-bold mb-3">4</div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">Enjoy!</h4>
            <p class="text-gray-600 text-center">Start your bots and enjoy getting those difficult-to-purchase products!</p>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Process',
}
</script>
<style lang="scss">
.iconContainer {
  background-color: #2acb98;
  text-align: center;
  //padding: 10px;
  margin: 10px;
  border-radius: 50%;
  width:60px;
  height:60px;
}

.icon {
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  position:relative;
  top: calc(50% - 12px); /* 50% - 3/4 of icon height */
}
</style>