<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <TutorialsList />
      <Newsletter />

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'
import TutorialsList from '../components/cruip/partials/TutorialsList.vue'
import Newsletter from '../components/cruip/partials/Newsletter.vue'
import Footer from '../components/cruip/partials/Footer.vue'

export default {
  name: 'Tutorials',
  components: {
    Header,
    TutorialsList,
    Newsletter,
    Footer,
  },
}
</script>
