<template>
    <section class="relative">

        <!-- Illustration behind hero content -->
        <div class="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
            <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                <stop stop-color="#FFF" offset="0%" />
                <stop stop-color="#EAEAEA" offset="77.402%" />
                <stop stop-color="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fill-rule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>

        <div class="max-w-6xl mx-auto px-4 sm:px-6">

            <!-- Hero content -->
            <div class="pt-32 pb-12 md:pt-40 md:pb-20">

                <!-- Section header -->
                <div class="text-center pb-12 md:pb-16">
                    <h1 class="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4 text-black" data-aos="zoom-y-out">Create your own <span class="bg-clip-text text-transparent bg-gradient-to-r from-black to-secondary">custom</span> <span class="bg-clip-text text-transparent bg-gradient-to-r from-secondary to-primary">bots</span></h1>
                    <div class="max-w-3xl mx-auto">
                        <!-- <p class="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150"><b>BotBro</b> lets you create and manage custom bots to keep track of any website or product.</p> -->
                        <!-- <div v-if="!user" class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                            <div>
                                <a class="btn text-white bg-primary hover:bg-primary w-full mb-4 sm:w-auto sm:mb-0" href="/register">Sign Up</a>
                            </div>
                            <div>
                                <a class="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="#howitworks">Learn more</a>
                            </div>
                        </div>
                        <div v-else class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                            <div>
                            <a class="btn text-white bg-primary hover:bg-primary w-full mb-4 sm:w-auto sm:mb-0" href="/dashboard">Your Dashboard</a>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- Hero image -->
            <div id="heroimagecontainer">
                <a id="howitworks"></a>
                <div class="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
                    <div class="flex flex-col justify-center" >
                        <img class="mx-auto floating" :src="require('@/assets/botbro/Mascot.svg')" width="384" height="216" alt="Hero" />
                        <svg class="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto floating" width="384" height="216" viewBox="0 0 768 432" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-ill-a">
                        <stop stop-color="#FFF" offset="0%" />
                        <stop stop-color="#EAEAEA" offset="77.402%" />
                        <stop stop-color="#DFDFDF" offset="100%" />
                      </linearGradient>
                      <linearGradient x1="50%" y1="0%" x2="50%" y2="99.24%" id="hero-ill-b">
                        <stop stop-color="#FFF" offset="0%" />
                        <stop stop-color="#EAEAEA" offset="48.57%" />
                        <stop stop-color="#DFDFDF" stop-opacity="0" offset="100%" />
                      </linearGradient>
                      <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="hero-ill-e">
                        <stop stop-color="#4FD1C5" offset="0%" />
                        <stop stop-color="#81E6D9" offset="25.871%" />
                        <stop stop-color="#338CF5" offset="100%" />
                      </radialGradient>
                      <!-- <circle id="hero-ill-d" cx="384" cy="216" r="64" /> -->
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                      <circle fill-opacity=".04" fill="url(#hero-ill-a)" cx="384" cy="216" r="128" />
                      <circle fill-opacity=".16" fill="url(#hero-ill-b)" cx="384" cy="216" r="96" />
                      <g fill-rule="nonzero">
                        <use fill="#000" xlink:href="#hero-ill-d" />
                        <use fill="url(#hero-ill-e)" xlink:href="#hero-ill-d" />
                      </g>
                    </g>
                  </svg>
                    </div>
                  <a id="windows" @click="registerWindowsDownload()" class="absolute top-full bg-primary rounded-full font-medium group p-4 shadow-lg is-hoverable download" aria-controls="modal" href="https://botbro.s3.amazonaws.com/BotBro+Setup+0.0.1.exe" download>
                    <span class="ml-3">Windows<i class="fa fa-download"></i></span>
                  </a>
                  <a id="mac" @click="registerMacDownload()" class="absolute top-full bg-primary rounded-full font-medium group p-4 shadow-lg is-hoverable download" aria-controls="modal" href="https://botbro.s3.amazonaws.com/BotBro-0.0.1.pkg" download>
                    <span class="ml-3">macOS<i class="fa fa-download"></i></span>
                  </a>
                  
                </div>
                <div class="relative flex justify-center mb-20" data-aos="zoom-y-out" data-aos-delay="450">
                    <div class="flex flex-col justify-center" >
                        <h2 class="h2 mb-4 text-black mx-auto mt-4">How it works</h2>
                        <img class="mx-auto" :src="require('@/assets/cruip/images/hero-image-01.png')" width="768" height="432" alt="Hero" @click.prevent.stop="videoModalOpen = true"/>
                        <svg class="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="768" height="432" viewBox="0 0 768 432" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                      <!-- <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-ill-a">
                        <stop stop-color="#FFF" offset="0%" />
                        <stop stop-color="#EAEAEA" offset="77.402%" />
                        <stop stop-color="#DFDFDF" offset="100%" />
                      </linearGradient>
                      <linearGradient x1="50%" y1="0%" x2="50%" y2="99.24%" id="hero-ill-b">
                        <stop stop-color="#FFF" offset="0%" />
                        <stop stop-color="#EAEAEA" offset="48.57%" />
                        <stop stop-color="#DFDFDF" stop-opacity="0" offset="100%" />
                      </linearGradient>
                      <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="hero-ill-e">
                        <stop stop-color="#4FD1C5" offset="0%" />
                        <stop stop-color="#81E6D9" offset="25.871%" />
                        <stop stop-color="#338CF5" offset="100%" />
                      </radialGradient>
                      <circle id="hero-ill-d" cx="384" cy="216" r="64" /> -->
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                      <circle fill-opacity=".04" fill="url(#hero-ill-a)" cx="384" cy="216" r="128" />
                      <circle fill-opacity=".16" fill="url(#hero-ill-b)" cx="384" cy="216" r="96" />
                      <g fill-rule="nonzero">
                        <use fill="#000" xlink:href="#hero-ill-d" />
                        <use fill="url(#hero-ill-e)" xlink:href="#hero-ill-d" />
                      </g>
                    </g>
                  </svg>
                    </div>
                    <button id="videoModalButton" class="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg" @click.prevent.stop="videoModalOpen = true" aria-controls="modal">
                  <svg class="w-6 h-6 fill-current text-gray-400 group-hover:text-primary flex-shrink-0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                    <path d="M10 17l6-5-6-5z" />
                  </svg>
                  <span class="ml-3">Watch video</span>
                </button>
                </div>

                <!-- Modal -->
                <Modal id="modal" ariaLabel="modal-headline" :show="videoModalOpen" @handleClose="videoModalOpen = false">
                    <div class="relative pb-9/16">
                        <!-- <iframe class="absolute w-full h-full" src="https://player.vimeo.com/video/572321748" title="Video" allowFullScreen></iframe> -->
                        <iframe class="absolute w-full h-full" src="https://www.youtube.com/embed/hg-7oMEisiE" title="Video" allowFullScreen></iframe>
                    </div>
                </Modal>

            </div>

        </div>

        </div>
    </section>
</template>

<script>
import Modal from './../utils/Modal.vue'
import { mapGetters } from "vuex";

export default {
    name: 'HeroHome',
    methods: {
      scrollElementIntoView(element) {
          document.getElementById(element).scrollIntoView(true)
      },
      registerWindowsDownload() {
        this.$gtag.event(['WindowsDownload'], {
        'event_category': 'engagement',
      })
      },
      registerMacDownload() {
        this.$gtag.event(['MacDownload'], {
        'event_category': 'engagement',
      })
    }
  },
    components: {
        Modal,
    },
    computed: {
        ...mapGetters({
            user: "auth/authUser",
        }),
    },
    data: function() {
        return {
            videoModalOpen: false,
        }
    },
}
</script>

<style lang="scss">
#videoModalButton {
}

i {
  margin-left: 10px;
}

.download {
  background-color: #2ACB98;
  color: white;
  width: 150px;
  margin-top: -50px;
  text-align: center;
}

#windows {
  background-color: #2ACB98;
  color: white;
  text-align: center;
  transform: translate(-90px,  0px);
}

#mac {
  background-color: #2ACB98;
  color: white;
  text-align: center;
  transform: translate(90px,  0px);
}

#heroimagecontainer {
  margin-top: -150px;
  margin-bottom: -100px;
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }
}
</style>