<template>
  <header
    class="fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out"
    :class="{ 'bg-white blur shadow-lg': !top }"
  >
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-16 md:h-20">
        <!-- Site branding -->
        <div class="flex-shrink-0 mr-4">
          <!-- Logo -->
          <router-link :to="'/'" class="logo" href="/">
            <div id="logocontainer">
              <div id="main-logo"><img id="logoheader" src="../../../assets/botbro/transparent Mascot.png"></div>
              <div id="logoTextContainer">
                  <h1 id="logoText1">Bot</h1>
                  <!-- <h1 id="logoText2">-web-</h1> -->
                  <h1 id="logoText3">Bro</h1>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:flex-grow">
          <!-- Desktop menu links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <router-link
                to="/"
                class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >Home</router-link
              >
            </li>
            <!-- <li>
              <router-link
                to="/faq"
                class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >FAQ</router-link
              >
            </li> -->
             <li>
              <router-link
                to="/features"
                class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >Features</router-link
              >
            </li>
            <li>
              <router-link
                to="/blog"
                class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >Blog</router-link
              >
            </li>
            <li>
              <router-link
                to="/contact"
                class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >Contact</router-link
              >
            </li>
            <!-- <li>
              <router-link
                to="/docs"
                class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >Documentation</router-link
              >
            </li> -->
            <!-- <li>
              <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
            </li>             -->
            <!-- 1st level: hover -->
            <Dropdown title="Resources">
              <li>
                <router-link
                  to="/docs"
                  class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                  >Documentation</router-link
                >
              </li>
              <li>
              <router-link
                  to="/faq"
                  class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                  >FAQ</router-link
                >
              </li>
            </Dropdown>
          </ul>

          <!-- Desktop sign in links -->
          <ul v-if="user">
            <Dropdown title="Account">
              <!-- <li>
                <router-link
                  :to="{ name: 'PageStore' }"
                  class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                  >Your Store</router-link
                >
              </li> -->
              <li>
                <router-link
                  :to="{ name: 'PageAccount' }"
                  class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                  >Manage Account</router-link
                >
              </li>

              <hr class="navbar-divider" />
              <a
                @click.prevent="logout"
                class="navbar-item"
                id="logoutButtonDiv"
              >
                <strong>Logout</strong>
              </a>
            </Dropdown>
          </ul>
          <ul v-else class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <router-link
                to="/login"
                class="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                >Sign in</router-link
              >
            </li>
            <li id="signUp">
              <router-link
                to="/register"
                class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"
              >
                <span>Sign up</span>
                <svg
                  class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                    fill-rule="nonzero"
                  />
                </svg>
              </router-link>
            </li>
          </ul>
        </nav>

        <!-- Mobile menu -->
        <div class="flex md:hidden">
          <!-- Hamburger button -->
          <button
            class="hamburger"
            ref="hamburger"
            :class="{ active: mobileNavOpen }"
            aria-controls="mobile-nav"
            :aria-expanded="mobileNavOpen"
            @click="mobileNavOpen = !mobileNavOpen"
          >
            <span class="sr-only">Menu</span>
            <svg
              class="w-6 h-6 fill-current text-gray-900"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="4" width="24" height="2" />
              <rect y="11" width="24" height="2" />
              <rect y="18" width="24" height="2" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <nav
              id="mobile-nav"
              ref="mobileNav"
              v-show="mobileNavOpen"
              class="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
            >
              <ul class="px-5 py-2">
                <li>
                  <router-link
                    to="/"
                    class="flex text-gray-600 hover:text-gray-900 py-2"
                    >Home</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/features"
                    class="flex text-gray-600 hover:text-gray-900 py-2"
                    >Features</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/blog"
                    class="flex text-gray-600 hover:text-gray-900 py-2"
                    >Blog</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/contact"
                    class="flex text-gray-600 hover:text-gray-900 py-2"
                    >Contact</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/docs"
                    class="flex text-gray-600 hover:text-gray-900 py-2"
                    >Documentation</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/faq"
                    class="flex text-gray-600 hover:text-gray-900 py-2"
                    >FAQ</router-link
                  >
                </li>
                <div v-if="!user">
                  <li>
                    <router-link
                      to="/login"
                      class="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                      >Sign in</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/register"
                      class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2"
                    >
                      <span>Sign up</span>
                      <svg
                        class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                          fill="#999"
                          fill-rule="nonzero"
                        />
                      </svg>
                    </router-link>
                  </li>
                </div>
                <div v-else>
                  <ul>
                    <li class="py-2 my-2 border-t border-b border-gray-200">
                      <span class="flex text-gray-600 hover:text-gray-900 py-2"
                        >Account</span
                      >
                      <ul class="pl-4">
                        <!-- <li>
                          <router-link
                            to="/store"
                            class="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2"
                            >Your Store</router-link
                          >
                        </li> -->
                        <li>
                          <router-link
                            to="/account"
                            class="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2"
                            >Account</router-link
                          >
                        </li>
                        <!-- <li>
                      <router-link
                        to="/404"
                        class="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2"
                        >404</router-link
                      >
                    </li> -->
                      </ul>
                    </li>

                    <hr class="navbar-divider" />
                    <a
                      @click.prevent="logout"
                      class="navbar-item"
                      id="logoutButtonDiv"
                    >
                      <strong>Logout</strong>
                    </a>
                  </ul>
                </div>
              </ul>
            </nav>
          </transition>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Dropdown from "./../utils/Dropdown.vue";
import { mapGetters } from "vuex";
export default {
  name: "Header",
  components: {
    Dropdown,
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
  },
  data: function () {
    return {
      mobileNavOpen: false,
      top: true,
    };
  },
  watch: {
    '$route' () {
      this.mobileNavOpen = false;
    }
  },
  methods: {
    clickOutside(e) {
      if (
        !this.mobileNavOpen ||
        this.$refs.mobileNav.contains(e.target) ||
        this.$refs.hamburger.contains(e.target)
      )
        return;
      this.mobileNavOpen = false;
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return;
      this.mobileNavOpen = false;
    },
    handleScroll() {
      this.top = window.pageYOffset > 10 ? false : true;
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
        // Get all "navbar-burger" elements
        const navbarBurger = document.getElementById("burgerMenuButton");
        const navbarMenu = document.getElementById("navMenu");
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        navbarBurger.classList.toggle("is-active");
        navbarMenu.classList.toggle("is-active");
      });
    },
    toggleNavbar() {
      // Get all "navbar-burger" elements
      const navbarBurger = document.getElementById("burgerMenuButton");

      const navbarMenu = document.getElementById("navMenu");
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      navbarBurger.classList.toggle("is-active");
      navbarMenu.classList.toggle("is-active");
    },
  },
  mounted() {
    document.addEventListener("click", this.clickOutside);
    document.addEventListener("keydown", this.keyPress);
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutside);
    document.removeEventListener("keydown", this.keyPress);
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
#signUp {

  :hover {
    color: white;
  }
}

#logocontainer {
  margin-left: 30px;
}

#logoheader {
  width: 30px;
  display: inline;
  transform: translate(-35px, 21px);
}

#logoTextContainer {
    //background-color: #02809050;
    border-radius: 5px;
    height: 100%;
}

#logoText1 {
    color: #000000;
    display: inline;
    font-weight: bold;
    margin: auto;
    font-size: 20px;
}

#logoText2 {
    color: #F45B69;
    display: inline;
    font-weight: bold;
    font-size: 20px;

}

#logoText3 {
    color: #2ACB98;
    display: inline;
    font-weight: bold;
    font-size: 20px;
}
</style>