<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6" data-aos="zoom-y-out">
      <div class="pt-12 md:pt-12">

        <!-- Section header -->
        <div class="pb-12">
          <h2 class="h2 text-black">Benefits</h2>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto">
          <table class="table-auto w-full border-b border-gray-200">
            <!-- Table header -->
            <!-- <thead>
              <tr class="text-base sm:text-lg border-t border-gray-200">
                <th class="text-bold text-left pr-2 py-4 min-w-48">Breakdown of benefits</th>
                <th class="text-bold text-center px-2 py-4">Starter</th>
                <th class="text-bold text-center px-2 py-4">Premium</th>
                <th class="text-bold text-center px-2 py-4">Agency</th>
                <th class="text-bold text-center pl-2 py-4">Enterprise</th>
              </tr>
            </thead> -->
            <!-- Table body -->
            <tbody>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">50% Recurring Commissions</div>
                  <div class="text-gray-600">For every customer you refer, earn 50% of the cost of their subscription every month</div>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <td class="text-sm pl-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td> -->
              </tr>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">Analytics Dashboard</div>
                  <div class="text-gray-600">Keep track of your earnings and referral analytics via your personal dashboard</div>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">Unlimited</td>
                <td class="text-sm px-2 py-4 text-center font-medium">Unlimited</td>
                <td class="text-sm pl-2 py-4 text-center font-medium">Unlimited</td> -->
              </tr>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">Personal Link</div>
                  <div class="text-gray-600">Have your own customizable link to share with your audience</div>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">20</td>
                <td class="text-sm px-2 py-4 text-center font-medium">50</td>
                <td class="text-sm pl-2 py-4 text-center font-medium">Unlimited</td> -->
              </tr>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">Long Conversion Window</div>
                  <div class="text-gray-600">Anyone that purchases a subscription within 45 days of clicking your link will be counted as a referral</div>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <td class="text-sm pl-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td> -->
              </tr>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">Quick Payouts</div>
                  <div class="text-gray-600">Commissions earned are payable after 7 days</div>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td> -->
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td> -->
                <td class="text-sm pl-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
              </tr>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">PayPal Integration</div>
                  <div class="text-gray-600">Receive payouts directly to your PayPal account</div>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td> -->
                <td class="text-sm pl-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
              </tr>
              <!-- Row -->
              <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">Dedicated Support</div>
                  <div class="text-gray-600">Reach out to us at any time and receive a response within a few hours</div>
                </td>
                <!-- <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td> -->
                <td class="text-sm pl-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
              </tr>
              <!-- Row -->
              <!-- <tr class="border-t border-gray-200">
                <td class="text-sm sm:text-base pr-2 py-4">
                  <div class="font-medium underline">Account manager</div>
                  <div class="text-gray-600">Condimentum id venenatis a condimentum vitae</div>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm px-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-gray-400 opacity-75 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.72 1.293a1 1 0 00-1.415 0L6.012 4.586 2.72 1.293a1 1 0 00-1.414 1.414L4.598 6 1.305 9.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L7.426 6l3.293-3.293a1 1 0 000-1.414z" />
                  </svg>
                </td>
                <td class="text-sm pl-2 py-4 text-center font-medium">
                  <svg class="w-3 h-3 fill-current text-green-500 inline-flex" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AffiliateTable',
}
</script>