<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <div class="max-w-3xl mx-auto lg:max-w-none">

          <article>

            <!-- Article header -->
            <header class="max-w-3xl mx-auto mb-20">
              <!-- Title -->
              <h1 class="h1 text-center mb-4 text-black">Auto Checkout Walmart Bot</h1>
            </header>

            <!-- Article content -->
            <div class="lg:flex lg:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                  <h4 class="text-lg font-bold leading-snug tracking-tight mb-4 text-black">Table of contents</h4>
                  <ul class="font-medium -my-1">
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('introduction')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Introduction</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('installation')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Installation</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('configuration')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Configuring Settings</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('creating')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Creating the Bot</span>
                      </a>
                    </li>
                    <li class="py-1">
                      <a class="flex items-center hover:underline" @click="scrollElementIntoView('running')">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>Running the Bot</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>

              <!-- Main content -->
              <div>

                <!-- Article meta -->
                <div class="flex items-center mb-6">
                  <div class="flex flex-shrink-0 mr-3">
                    <a class="relative" href="#0">
                      <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                      <img class="relative rounded-full" :src="require('@/assets/cruip/images/colin-moran.jpg')" width="32" height="32" alt="Colin Moran" />
                    </a>
                  </div>
                  <div>
                    <span class="text-gray-600">By </span>
                    <a class="font-medium hover:underline" href="https://www.linkedin.com/in/colin-p-moran/" target="_blank">Colin Moran</a>
                    <span class="text-gray-600"> · October 21, 2021</span>
                  </div>
                </div>
                <hr class="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                <!-- Article body -->
                <div class="text-lg text-gray-600">
                  <p id="introduction" class="mb-8" style="scroll-margin-top: 100px;">
                    In this article, we will show you how to create an auto checkout Walmart bot to buy a product when it comes back in stock. We will use a PlayStation 5 as an example.
                  </p>
                  <p class="mb-8">
                    Let's begin.
                  </p>
                  <figure class="mb-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/walmart-bot-complete.png')" width="768" height="432" alt="Walmart Bot in BotBro" />
                  </figure>
                  <h3 id="installation" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Download and Install BotBro</h3>
                  <p class="mb-8">
                    First things first. You need to download and install BotBro. If you have already done this you can skip to the next step.
                  </p>
                  <div class="columns">
                    <div class="column">
                      <a id="" @click="registerWindowsDownload()" style="width: 300px; display: block; text-align: center;" class="top-full bg-primary rounded-full font-medium group p-4 shadow-lg is-hoverable text-white" aria-controls="modal" href="https://botbro.s3.amazonaws.com/BotBro+Setup+0.0.1.exe" download>
                        <span class="ml-3">Windows Download<i class="fa fa-download"></i></span>
                      </a>
                    </div>
                    <div class="">
                      <a id="" @click="registerMacDownload()" style="width: 300px; display: block; text-align: center;" class="top-full bg-primary rounded-full font-medium group p-4 m-4 shadow-lg is-hoverable text-white" aria-controls="modal" href="https://botbro.s3.amazonaws.com/BotBro-0.0.1.pkg" download>
                        <span class="ml-3">macOS Download<i class="fa fa-download"></i></span>
                      </a>
                    </div>
                  </div>
                  <p class="mb-8">
                  </p>
                  <p class="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                    IMPORTANT: When you run the installer your firewall will probably ask if you are sure you want to open the file.<br><br> For Windows: Click "More Info" and then "Run Anyways." <br><br>For Mac: Open System Preferences, then go to the Security & Privacy tab. Towards the bottom you will see a button that says "Open Anyway."
                  </p>
                  <p class="mb-8">
                    Before you are able to sign in and use the application you will need to
                    <router-link to="/register">
                      <a class="inline-flex items-center text-base text-blue-600 font-medium hover:underline">
                        <span>create an account</span>
                      </a>
                    </router-link>
                    and sign up for the free trial.
                  </p>
                  <p class="mb-8">
                    Once you have created your account and signed up for the free trial you can sign into the application and begin using it.
                  </p>
                  <h3 id="configuration" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Configuring Settings</h3>
                  <p class="mb-8">Before creating our Walmart bot, let's take a look at our settings. Currently there are two primary categories of settings in BotBro, "Notification" settings and "Auto Purchase" settings.</p>
                  <p class="mb-8">First let's take a look at our notification settings by clicking the "Notification Settings" button.</p>
                  <figure class="mb-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/notification-settings.png')" width="768" height="432" alt="BotBro notification settings" />
                  </figure>
                  <p class="mb-8">These are all pretty self explanatory, the important thing is to enter your phone number and email and then check the boxes for the notifications you would like to receive. The notifications will be sent whenever a bot succeeds.</p>
                  <p class="mb-8">Now let's look at our "Auto Purchase" settings by clicking the "Auto Purchase Settings" button.</p>
                  <figure class="mb-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/walmart-bot-auto-purchase-settings.png')" width="768" height="432" alt="BotBro Walmart bot auto purchase settings" />
                  </figure>
                  <p class="mb-8">This is where you tell the bot what credentials to use to sign in to your Walmart account to make purchases. You also need to include the default payment card security code because in Walmart's checkout process the bot will be asked to confirm the code.</p>
                  <p class="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                    IMPORTANT: Make sure that your Walmart account has a default payment method and default shipping address saved as these are what the Walmart bot will use.
                  </p>
                  <h3 id="creating" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Creating the Bot</h3>
                  <p>
                    Now that we have our settings how we want them, we can begin making our Walmart bot. All we need to do is fill in the 5 fields and click the "Add" button.
                  </p>
                  <ul class="list-disc list-inside mb-8 mt-4">
                    <li class="mb-1">
                      <span class="">Bot Type - Leave this on the default of "Auto Purchase"</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Name - We'll call ours "Walmart Bot"</span>
                    </li>
                    <li class="mb-1">
                      <span class="">URL - The URL for the product page you want the bot to purchase from, we will use this <a class="text-primary" href="https://www.walmart.com/ip/Sony-PlayStation-5-Video-Game-Console/363472942" target="_blank">PS5 page</a></span>
                    </li>
                    <li class="mb-1">
                      <span class="">Refresh Rate - This is how often (in seconds) you want the bot to check the page. We suggest 10 seconds or more to avoid being blocked by the Walmart website</span>
                    </li>
                    <li class="mb-1">
                      <span class="">Website - Make sure this is set to Walmart. As of now BotBro only supports Auto Checkout for Walmart and Target, but more will be added in the very near future</span>
                    </li>
                  </ul>
                  <figure class="mb-8 mt-8">
                    <img class="w-full rounded" :src="require('@/assets/cruip/images/botbro-landing-page.png')" width="768" height="432" alt="BotBro landing page" />
                  </figure>
                  <p class="mb-8">Once you have filled in all the settings, just click the "Add" button to create the bot. After clicking the button you should see a new bot on the left side under the "Your Bots" heading.</p>
                  <div>
                    <h3 id="running" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Running the Bot</h3>
                    <p class="mb-8">To run the Walmart bot you simply need to click the play button. With most bots you can run them in the background by toggling the visiblity with the "Eye" icon, but unfortunately Walmart is able to detect that and will block the bot. So it must be run in "Visible" mode.</p>
                    <figure class="mb-8 mt-8">
                      <img class="w-full rounded" :src="require('@/assets/cruip/images/walmart-bot-running.png')" width="768" height="432" alt="BotBro Walmart bot running" />
                    </figure>
                    <p class="mb-8">The Walmart bot will run until it successfully purchases the product or until you click the stop button. If the Walmart bot successfully purchases the product it will send a notification to you.</p>
                  </div>
                  <div>
                    <hr class="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                    <div class="mt-8">
                      Interested in more tips like this? Check out the rest of our blog.
                    </div>
                    <div class="mt-6">
                      <router-link to="/blog" class="inline-flex items-center text-base text-primary font-medium hover:underline">
                        <svg class="w-3 h-3 fill-current text-primary flex-shrink-0 mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                        </svg>
                        <span>Back to the blog</span>
                      </router-link>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <!-- Article footer -->
          </article>

        </div>

      </div>
    </div>
  </section>

    </main>
  </div>
</template>

<script>
import Header from '../components/cruip/partials/Header.vue'
export default {
  name: 'PageBlogPostWalmartAutoPurchaseBot',
  methods: {
      scrollElementIntoView(element) {
          document.getElementById(element).scrollIntoView(true)
      },
      registerWindowsDownload() {
        this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/peuVCMj7svkCEKGZj5MD'})
        this.$gtag.event(['WindowsDownload'], {
        'event_category': 'engagement',
      })
      },
      registerMacDownload() {
        this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/QSWmCL79svkCEKGZj5MD'})
        this.$gtag.event(['MacDownload'], {
        'event_category': 'engagement',
      })
      }
  },
  metaInfo: {
    // Children can override the title.
    title: "Auto Checkout Walmart Bot | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: 'In this article, we will show you how to create an auto checkout Walmart bot to buy a product when it comes back in stock. We will use a PlayStation 5 as an example.',
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/blog/walmart-bot" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Auto Checkout Walmart Bot | BotBro" },
      {
        itemprop: "description",
        content: 'In this article, we will show you how to create an auto checkout Walmart bot to buy a product when it comes back in stock. We will use a PlayStation 5 as an example.',
      },
    ],
  },
  components: {
    Header
  },
}
</script>
