import axios from 'axios'

const axiosInstance = axios.create({
  timeout: 3000
})

axiosInstance.interceptors.request.use(function(config) {
  const token = localStorage.getItem('meetuper-jwt') || ''

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, function (err) {
  return Promise.reject(err)
})

// axiosInstance.interceptors.request.use(config => {
//   app.$Progress.start(); // for every request start the progress
//   return config;
// });

// axiosInstance.interceptors.response.use(response => {
//   app.$Progress.finish(); // finish when a response is received
//   return response;
// });

export default axiosInstance
