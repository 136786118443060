<template>
  <div>
    <div class="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main class="flex-grow">
        <div id="registerContainer">
          <div class="container has-text-centered">
            <div class="column is-4 is-offset-4">
              <div class="pt-32 pb-12 md:pt-40 md:pb-20">
                <div class="max-w-3xl mx-auto text-center pb-12">
                  <h1 class="h1 text-black">Register</h1>
                </div>
                <div class="box">
                  <!-- <div class="avatarBox">
                                                <div class="avatarDiv">
                                                    <figure class="avatar">
                                                        <img :src="avatar">
                                                    </figure>
                                                </div>
                                            </div> -->
                  <!-- <div id="generateAvatarBox">
                                            <p class="content has-text-black">Don't like your avatar?</p>
                                            <button id="generateAvatar" class="button is-medium is-warning has-text-white is-hoverable" @click="randAvatar">Randomize Avatar</button>
                                            <p class="content has-text-grey is-small">You can use your own image link if you want, don't worry.</p>
                                        </div> -->
                  <form>
                    <div class="field">
                      <div class="control">
                        <input
                          v-model="form.username"
                          @blur="$v.form.username.$touch()"
                          class="input is-medium transition"
                          type="text"
                          placeholder="Username"
                        />
                        <div v-if="$v.form.username.$error" class="form-error">
                          <span
                            v-if="!$v.form.username.required"
                            class="help is-danger"
                            >Username is required</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div class="columns is-mobile">
                                            <div class="column is-half">
                                                <div class="field">
                                                    <div class="control">
                                                        <input v-model="form.firstName" @blur="$v.form.firstName.$touch()" class="input is-medium transition" type="text" placeholder="First Name">
                                                        <div v-if="$v.form.firstName.$error" class="form-error">
                                                            <span v-if="!$v.form.firstName.required" class="help is-danger">First name is required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column is-half">
                                                <div class="field">
                                                    <div class="control">
                                                        <input v-model="form.lastName" @blur="$v.form.lastName.$touch()" class="input is-medium transition" type="text" placeholder="Last Name">
                                                        <div v-if="$v.form.lastName.$error" class="form-error">
                                                            <span v-if="!$v.form.lastName.required" class="help is-danger">Last name is required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                    <!-- <div class="field">
                                                <div class="control">
                                                    <input v-model="form.address" @blur="$v.form.address.$touch()" class="input is-medium transition" type="address" placeholder="Address Line 1">
                                                    <div v-if="$v.form.address.$error" class="form-error">
                                                        <span v-if="!$v.form.address.required" class="help is-danger">Address is required</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="columns is-mobile">
                                                <div class="column is-half">
                                                    <div class="field">
                                                        <div class="control">
                                                            <input v-model="form.city" @blur="$v.form.city.$touch()" class="input is-medium transition" type="city" placeholder="City">
                                                            <div v-if="$v.form.city.$error" class="form-error">
                                                                <span v-if="!$v.form.city.required" class="help is-danger">City is required</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-half">
                                                    <div class="field">
                                                <div class="control">
                                                    <div class="select is-medium">
                                                        <select v-model="form.state">
                                                            <option disabled value="">State</option>
                                                            <option value="AL">Alabama</option>
                                                            <option value="AK">Alaska</option>
                                                            <option value="AZ">Arizona</option>
                                                            <option value="AR">Arkansas</option>
                                                            <option value="CA">California</option>
                                                            <option value="CO">Colorado</option>
                                                            <option value="CT">Connecticut</option>
                                                            <option value="DE">Delaware</option>
                                                            <option value="DC">District Of Columbia</option>
                                                            <option value="FL">Florida</option>
                                                            <option value="GA">Georgia</option>
                                                            <option value="HI">Hawaii</option>
                                                            <option value="ID">Idaho</option>
                                                            <option value="IL">Illinois</option>
                                                            <option value="IN">Indiana</option>
                                                            <option value="IA">Iowa</option>
                                                            <option value="KS">Kansas</option>
                                                            <option value="KY">Kentucky</option>
                                                            <option value="LA">Louisiana</option>
                                                            <option value="ME">Maine</option>
                                                            <option value="MD">Maryland</option>
                                                            <option value="MA">Massachusetts</option>
                                                            <option value="MI">Michigan</option>
                                                            <option value="MN">Minnesota</option>
                                                            <option value="MS">Mississippi</option>
                                                            <option value="MO">Missouri</option>
                                                            <option value="MT">Montana</option>
                                                            <option value="NE">Nebraska</option>
                                                            <option value="NV">Nevada</option>
                                                            <option value="NH">New Hampshire</option>
                                                            <option value="NJ">New Jersey</option>
                                                            <option value="NM">New Mexico</option>
                                                            <option value="NY">New York</option>
                                                            <option value="NC">North Carolina</option>
                                                            <option value="ND">North Dakota</option>
                                                            <option value="OH">Ohio</option>
                                                            <option value="OK">Oklahoma</option>
                                                            <option value="OR">Oregon</option>
                                                            <option value="PA">Pennsylvania</option>
                                                            <option value="RI">Rhode Island</option>
                                                            <option value="SC">South Carolina</option>
                                                            <option value="SD">South Dakota</option>
                                                            <option value="TN">Tennessee</option>
                                                            <option value="TX">Texas</option>
                                                            <option value="UT">Utah</option>
                                                            <option value="VT">Vermont</option>
                                                            <option value="VA">Virginia</option>
                                                            <option value="WA">Washington</option>
                                                            <option value="WV">West Virginia</option>
                                                            <option value="WI">Wisconsin</option>
                                                            <option value="WY">Wyoming</option>
                                                        </select>
                                                    </div>

                                                    <div v-if="$v.form.country.$error" class="form-error">
                                                        <span v-if="!$v.form.postalCode.required" class="help is-danger">Country is required</span>
                                                    </div>
                                                </div>
                                            </div>
                                                </div>
                                            </div>
                                            <div class="field">
                                                <div class="control">
                                                    <input v-model="form.postalCode" @blur="$v.form.state.$touch()" class="input is-medium transition" type="zip" placeholder="Postal Code">
                                                    <div v-if="$v.form.postalCode.$error" class="form-error">
                                                        <span v-if="!$v.form.postalCode.required" class="help is-danger">Postal code is required</span>
                                                    </div>
                                                </div>
                                            </div> -->
                    <!-- <div class="field">
                                            <div class="control has-icons-left">
                                                <input v-model="form.country" @blur="$v.form.state.$touch()" class="input is-medium transition" type="country" placeholder="Country">
                                                <div class="select is-medium">
                                                    <select v-model="form.country">
                                                            <option disabled value="">Country</option>
                                                            <option value="AF">Afghanistan</option>
                                                            <option value="AX">Åland Islands</option>
                                                            <option value="AL">Albania</option>
                                                            <option value="DZ">Algeria</option>
                                                            <option value="AS">American Samoa</option>
                                                            <option value="AD">Andorra</option>
                                                            <option value="AO">Angola</option>
                                                            <option value="AI">Anguilla</option>
                                                            <option value="AQ">Antarctica</option>
                                                            <option value="AG">Antigua and Barbuda</option>
                                                            <option value="AR">Argentina</option>
                                                            <option value="AM">Armenia</option>
                                                            <option value="AW">Aruba</option>
                                                            <option value="AU">Australia</option>
                                                            <option value="AT">Austria</option>
                                                            <option value="AZ">Azerbaijan</option>
                                                            <option value="BS">Bahamas</option>
                                                            <option value="BH">Bahrain</option>
                                                            <option value="BD">Bangladesh</option>
                                                            <option value="BB">Barbados</option>
                                                            <option value="BY">Belarus</option>
                                                            <option value="BE">Belgium</option>
                                                            <option value="BZ">Belize</option>
                                                            <option value="BJ">Benin</option>
                                                            <option value="BM">Bermuda</option>
                                                            <option value="BT">Bhutan</option>
                                                            <option value="BO">Bolivia, Plurinational State of</option>
                                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                            <option value="BA">Bosnia and Herzegovina</option>
                                                            <option value="BW">Botswana</option>
                                                            <option value="BV">Bouvet Island</option>
                                                            <option value="BR">Brazil</option>
                                                            <option value="IO">British Indian Ocean Territory</option>
                                                            <option value="BN">Brunei Darussalam</option>
                                                            <option value="BG">Bulgaria</option>
                                                            <option value="BF">Burkina Faso</option>
                                                            <option value="BI">Burundi</option>
                                                            <option value="KH">Cambodia</option>
                                                            <option value="CM">Cameroon</option>
                                                            <option value="CA">Canada</option>
                                                            <option value="CV">Cape Verde</option>
                                                            <option value="KY">Cayman Islands</option>
                                                            <option value="CF">Central African Republic</option>
                                                            <option value="TD">Chad</option>
                                                            <option value="CL">Chile</option>
                                                            <option value="CN">China</option>
                                                            <option value="CX">Christmas Island</option>
                                                            <option value="CC">Cocos (Keeling) Islands</option>
                                                            <option value="CO">Colombia</option>
                                                            <option value="KM">Comoros</option>
                                                            <option value="CG">Congo</option>
                                                            <option value="CD">Congo, the Democratic Republic of the</option>
                                                            <option value="CK">Cook Islands</option>
                                                            <option value="CR">Costa Rica</option>
                                                            <option value="CI">Côte d'Ivoire</option>
                                                            <option value="HR">Croatia</option>
                                                            <option value="CU">Cuba</option>
                                                            <option value="CW">Curaçao</option>
                                                            <option value="CY">Cyprus</option>
                                                            <option value="CZ">Czech Republic</option>
                                                            <option value="DK">Denmark</option>
                                                            <option value="DJ">Djibouti</option>
                                                            <option value="DM">Dominica</option>
                                                            <option value="DO">Dominican Republic</option>
                                                            <option value="EC">Ecuador</option>
                                                            <option value="EG">Egypt</option>
                                                            <option value="SV">El Salvador</option>
                                                            <option value="GQ">Equatorial Guinea</option>
                                                            <option value="ER">Eritrea</option>
                                                            <option value="EE">Estonia</option>
                                                            <option value="ET">Ethiopia</option>
                                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                                            <option value="FO">Faroe Islands</option>
                                                            <option value="FJ">Fiji</option>
                                                            <option value="FI">Finland</option>
                                                            <option value="FR">France</option>
                                                            <option value="GF">French Guiana</option>
                                                            <option value="PF">French Polynesia</option>
                                                            <option value="TF">French Southern Territories</option>
                                                            <option value="GA">Gabon</option>
                                                            <option value="GM">Gambia</option>
                                                            <option value="GE">Georgia</option>
                                                            <option value="DE">Germany</option>
                                                            <option value="GH">Ghana</option>
                                                            <option value="GI">Gibraltar</option>
                                                            <option value="GR">Greece</option>
                                                            <option value="GL">Greenland</option>
                                                            <option value="GD">Grenada</option>
                                                            <option value="GP">Guadeloupe</option>
                                                            <option value="GU">Guam</option>
                                                            <option value="GT">Guatemala</option>
                                                            <option value="GG">Guernsey</option>
                                                            <option value="GN">Guinea</option>
                                                            <option value="GW">Guinea-Bissau</option>
                                                            <option value="GY">Guyana</option>
                                                            <option value="HT">Haiti</option>
                                                            <option value="HM">Heard Island and McDonald Islands</option>
                                                            <option value="VA">Holy See (Vatican City State)</option>
                                                            <option value="HN">Honduras</option>
                                                            <option value="HK">Hong Kong</option>
                                                            <option value="HU">Hungary</option>
                                                            <option value="IS">Iceland</option>
                                                            <option value="IN">India</option>
                                                            <option value="ID">Indonesia</option>
                                                            <option value="IR">Iran, Islamic Republic of</option>
                                                            <option value="IQ">Iraq</option>
                                                            <option value="IE">Ireland</option>
                                                            <option value="IM">Isle of Man</option>
                                                            <option value="IL">Israel</option>
                                                            <option value="IT">Italy</option>
                                                            <option value="JM">Jamaica</option>
                                                            <option value="JP">Japan</option>
                                                            <option value="JE">Jersey</option>
                                                            <option value="JO">Jordan</option>
                                                            <option value="KZ">Kazakhstan</option>
                                                            <option value="KE">Kenya</option>
                                                            <option value="KI">Kiribati</option>
                                                            <option value="KP">Korea, Democratic People's Republic of</option>
                                                            <option value="KR">Korea, Republic of</option>
                                                            <option value="KW">Kuwait</option>
                                                            <option value="KG">Kyrgyzstan</option>
                                                            <option value="LA">Lao People's Democratic Republic</option>
                                                            <option value="LV">Latvia</option>
                                                            <option value="LB">Lebanon</option>
                                                            <option value="LS">Lesotho</option>
                                                            <option value="LR">Liberia</option>
                                                            <option value="LY">Libya</option>
                                                            <option value="LI">Liechtenstein</option>
                                                            <option value="LT">Lithuania</option>
                                                            <option value="LU">Luxembourg</option>
                                                            <option value="MO">Macao</option>
                                                            <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                                            <option value="MG">Madagascar</option>
                                                            <option value="MW">Malawi</option>
                                                            <option value="MY">Malaysia</option>
                                                            <option value="MV">Maldives</option>
                                                            <option value="ML">Mali</option>
                                                            <option value="MT">Malta</option>
                                                            <option value="MH">Marshall Islands</option>
                                                            <option value="MQ">Martinique</option>
                                                            <option value="MR">Mauritania</option>
                                                            <option value="MU">Mauritius</option>
                                                            <option value="YT">Mayotte</option>
                                                            <option value="MX">Mexico</option>
                                                            <option value="FM">Micronesia, Federated States of</option>
                                                            <option value="MD">Moldova, Republic of</option>
                                                            <option value="MC">Monaco</option>
                                                            <option value="MN">Mongolia</option>
                                                            <option value="ME">Montenegro</option>
                                                            <option value="MS">Montserrat</option>
                                                            <option value="MA">Morocco</option>
                                                            <option value="MZ">Mozambique</option>
                                                            <option value="MM">Myanmar</option>
                                                            <option value="NA">Namibia</option>
                                                            <option value="NR">Nauru</option>
                                                            <option value="NP">Nepal</option>
                                                            <option value="NL">Netherlands</option>
                                                            <option value="NC">New Caledonia</option>
                                                            <option value="NZ">New Zealand</option>
                                                            <option value="NI">Nicaragua</option>
                                                            <option value="NE">Niger</option>
                                                            <option value="NG">Nigeria</option>
                                                            <option value="NU">Niue</option>
                                                            <option value="NF">Norfolk Island</option>
                                                            <option value="MP">Northern Mariana Islands</option>
                                                            <option value="NO">Norway</option>
                                                            <option value="OM">Oman</option>
                                                            <option value="PK">Pakistan</option>
                                                            <option value="PW">Palau</option>
                                                            <option value="PS">Palestinian Territory, Occupied</option>
                                                            <option value="PA">Panama</option>
                                                            <option value="PG">Papua New Guinea</option>
                                                            <option value="PY">Paraguay</option>
                                                            <option value="PE">Peru</option>
                                                            <option value="PH">Philippines</option>
                                                            <option value="PN">Pitcairn</option>
                                                            <option value="PL">Poland</option>
                                                            <option value="PT">Portugal</option>
                                                            <option value="PR">Puerto Rico</option>
                                                            <option value="QA">Qatar</option>
                                                            <option value="RE">Réunion</option>
                                                            <option value="RO">Romania</option>
                                                            <option value="RU">Russian Federation</option>
                                                            <option value="RW">Rwanda</option>
                                                            <option value="BL">Saint Barthélemy</option>
                                                            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                                            <option value="KN">Saint Kitts and Nevis</option>
                                                            <option value="LC">Saint Lucia</option>
                                                            <option value="MF">Saint Martin (French part)</option>
                                                            <option value="PM">Saint Pierre and Miquelon</option>
                                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                                            <option value="WS">Samoa</option>
                                                            <option value="SM">San Marino</option>
                                                            <option value="ST">Sao Tome and Principe</option>
                                                            <option value="SA">Saudi Arabia</option>
                                                            <option value="SN">Senegal</option>
                                                            <option value="RS">Serbia</option>
                                                            <option value="SC">Seychelles</option>
                                                            <option value="SL">Sierra Leone</option>
                                                            <option value="SG">Singapore</option>
                                                            <option value="SX">Sint Maarten (Dutch part)</option>
                                                            <option value="SK">Slovakia</option>
                                                            <option value="SI">Slovenia</option>
                                                            <option value="SB">Solomon Islands</option>
                                                            <option value="SO">Somalia</option>
                                                            <option value="ZA">South Africa</option>
                                                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                            <option value="SS">South Sudan</option>
                                                            <option value="ES">Spain</option>
                                                            <option value="LK">Sri Lanka</option>
                                                            <option value="SD">Sudan</option>
                                                            <option value="SR">Suriname</option>
                                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                                            <option value="SZ">Swaziland</option>
                                                            <option value="SE">Sweden</option>
                                                            <option value="CH">Switzerland</option>
                                                            <option value="SY">Syrian Arab Republic</option>
                                                            <option value="TW">Taiwan, Province of China</option>
                                                            <option value="TJ">Tajikistan</option>
                                                            <option value="TZ">Tanzania, United Republic of</option>
                                                            <option value="TH">Thailand</option>
                                                            <option value="TL">Timor-Leste</option>
                                                            <option value="TG">Togo</option>
                                                            <option value="TK">Tokelau</option>
                                                            <option value="TO">Tonga</option>
                                                            <option value="TT">Trinidad and Tobago</option>
                                                            <option value="TN">Tunisia</option>
                                                            <option value="TR">Turkey</option>
                                                            <option value="TM">Turkmenistan</option>
                                                            <option value="TC">Turks and Caicos Islands</option>
                                                            <option value="TV">Tuvalu</option>
                                                            <option value="UG">Uganda</option>
                                                            <option value="UA">Ukraine</option>
                                                            <option value="AE">United Arab Emirates</option>
                                                            <option value="GB">United Kingdom</option>
                                                            <option value="US" selected>United States</option>
                                                            <option value="UM">United States Minor Outlying Islands</option>
                                                            <option value="UY">Uruguay</option>
                                                            <option value="UZ">Uzbekistan</option>
                                                            <option value="VU">Vanuatu</option>
                                                            <option value="VE">Venezuela, Bolivarian Republic of</option>
                                                            <option value="VN">Viet Nam</option>
                                                            <option value="VG">Virgin Islands, British</option>
                                                            <option value="VI">Virgin Islands, U.S.</option>
                                                            <option value="WF">Wallis and Futuna</option>
                                                            <option value="EH">Western Sahara</option>
                                                            <option value="YE">Yemen</option>
                                                            <option value="ZM">Zambia</option>
                                                            <option value="ZW">Zimbabwe</option>
                                                        </select>
                                                    <span class="icon is-left">
                                                        <i class="fas fa-globe"></i>
                                                    </span>
                                                </div>

                                                <div v-if="$v.form.country.$error" class="form-error">
                                                    <span v-if="!$v.form.postalCode.required" class="help is-danger">Country is required</span>
                                                </div>
                                            </div>
                                        </div> -->
                    <div class="field">
                      <div class="control">
                        <input
                          v-model="form.email"
                          @blur="$v.form.email.$touch()"
                          class="input is-medium transition"
                          type="email"
                          placeholder="Email"
                        />
                        <div v-if="$v.form.email.$error" class="form-error">
                          <span
                            v-if="!$v.form.email.required"
                            class="help is-danger"
                            >Email is required</span
                          >
                          <span
                            v-if="!$v.form.email.email"
                            class="help is-danger"
                            >Email address is not valid</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div class="field" hidden>
                                                <div class="control">
                                                    <input v-model="form.avatar" @blur="$v.form.avatar.$touch()" class="input is-medium transition" type="text" placeholder="Profile Picture" autocomplete="">
                                                    <div v-if="$v.form.avatar.$error" class="form-error">
                                                        <span v-if="!$v.form.avatar.url" class="help is-danger">Url format is not valid!</span>
                                                    </div>
                                                </div>
                                            </div> -->
                    <div class="field">
                      <div class="control">
                        <input
                          v-model="form.password"
                          @blur="$v.form.password.$touch()"
                          class="input is-medium transition"
                          type="password"
                          placeholder="Password"
                          autocomplete="new-password"
                        />
                        <div v-if="$v.form.password.$error" class="form-error">
                          <span
                            v-if="!$v.form.password.required"
                            class="help is-danger"
                            >Password is required</span
                          >
                          <span
                            v-if="!$v.form.password.minLength"
                            class="help is-danger"
                            >Password minimum length is 6 letters</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="control">
                        <input
                          v-model="form.passwordConfirmation"
                          @blur="$v.form.passwordConfirmation.$touch()"
                          class="input is-medium transition"
                          type="password"
                          placeholder="Confirm Password"
                          autocomplete="off"
                        />
                        <div
                          v-if="$v.form.passwordConfirmation.$error"
                          class="form-error"
                        >
                          <span
                            v-if="!$v.form.passwordConfirmation.required"
                            class="help is-danger"
                            >Password is required</span
                          >
                          <span
                            v-if="!$v.form.passwordConfirmation.sameAsPassword"
                            class="help is-danger"
                            >Password confirmation should be the same as
                            password</span
                          >
                        </div>
                      </div>
                    </div>
                    <button
                      @click.prevent="register"
                      type="submit"
                      class="button is-primary is-large is-fullwidth is-hoverable"
                    >
                      Register
                    </button>
                  </form>
                </div>
                <p class="has-text-grey">
                  <router-link :to="{ name: 'PageLogin' }"
                    >Already have an account?</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
//import { supportedFileType } from '@/helpers/validators'

export default {
  metaInfo: {
    // Children can override the title.
    title: "Sign Up | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "Create an account to begin selling your Tesla.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/register" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Sign Up | BotBro" },
      {
        itemprop: "description",
        content: "Create an account to begin creating bots!",
      },
    ],
  },
  data: function () {
    return {
      avatar:
        "https://avatars.dicebear.com/4.5/api/avataaars/bbbb.svg?top[]=longHair&hairColor[]=black&mouth[]=smile&skinColor[]=tanned,brown,light,brown,darkbrown,black,pale&eyes[]=happy",
      form: {
        username: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: "",
        postal: null,
        country: "US",
        email: null,
        avatar: null,
        password: null,
        passwordConfirmation: null,
      },
      avatarSeed: null,
      avatarReady: false,
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(4),
      },
      firstName: {
        //required
      },
      lastName: {
        //required
      },
      address: {
        //required
      },
      city: {
        //required
      },
      state: {
        //required
      },
      postalCode: {
        //required
      },
      country: {
        //required
      },
      email: {
        required,
        email,
      },
      // avatar: {
      //     url,
      //     supportedFileType
      // },
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    register() {
      const ref = this;
      this.$v.form.$touch();
      this.$store
        .dispatch("auth/registerUser", this.form)
        .then(function () {
          ref.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/qSkdCJDkhPkCEKGZj5MD'})
          ref.$gtag.event(['Register'], {
            'event_category': 'engagement',
          })
          ref.$router.push("/login")
        })
        .catch((errMessage) => {
          this.$toasted.error(errMessage, { duration: 5000 });
        });
      // window.fbq('track', 'CompleteRegistration');
    },
    rndStr(len) {
      let text = "";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    randAvatar() {
      this.avatarReady = false;
      let random = this.rndStr(8);
      this.avatarSeed = random;
      this.form.avatar =
        "https://avatars.dicebear.com/v2/avataaars/" +
        this.avatarSeed +
        ".svg?options[mouth][]=smile";
      this.avatarReady = true;
    },
  },
  // mounted() {
  //     let random = this.rndStr(8)
  //     this.avatarSeed = random
  //     this.form.avatar = 'https://avatars.dicebear.com/v2/avataaars/' + this.avatarSeed + '.svg?options[mouth][]=smile'
  //     this.avatarReady = true;
  // }
};
</script>

<style scoped lang='scss'>
// .columns {
//     padding-top: 0px;
//     padding-bottom: 0px;
//     margin-top: 0px;
//     margin-bottom: 0px;
// }
#registerContainer {
  // margin-top: 75px;
}

#generateAvatar {
  margin-bottom: 1em;
}

#generateAvatarBox {
  padding-bottom: 2em;
}

.hero.is-success {
  background: #ffff;
}

.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.hero-body {
  padding: 0px;
}

.avatarBox {
  width: 100%;
  text-align: center;
}

.avatarDiv {
  width: 70%;
  margin: auto;
}

.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}

button {
  margin: 0px;
}

.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;
}

p.subtitle {
  padding-top: 1rem;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.transition {
  transition: 0.3s;
}
</style>
