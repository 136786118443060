<template>
    <div class="flex flex-col min-h-screen overflow-hidden">
        <!-- Site header -->
        <Header />
        <!-- Page content -->
        <main class="flex-grow">
            <!-- Page sections -->
            <HeroHome></HeroHome>
            <!-- <FeaturesHome /> -->
            <FeaturesBlocks />
            <Process />
            <!-- <FeaturesTable /> -->
            <PricingTables />
            <!-- <FeaturesWorld /> -->
            <!-- <News /> -->
            <!-- <Cta />
            <Stats /> -->
        </main>

        <!-- Site footer -->
        <!-- <Footer /> -->
    </div>
</template>

<script>
import HeroHome from "../components/cruip/partials/HeroHome.vue";
// import FeaturesHome from "../components/cruip/partials/FeaturesHome.vue";
import FeaturesBlocks from "../components/cruip/partials/FeaturesBlocks.vue";
// import FeaturesWorld from "../components/cruip/partials/FeaturesWorld.vue";
// import News from "../components/cruip/partials/News.vue";
// import Cta from "../components/cruip/partials/Cta.vue";
// import FeaturesTable from "../components/cruip/partials/FeaturesTable.vue";
import PricingTables from "../components/cruip/partials/PricingTables.vue";
import Process from "../components/cruip/partials/Process.vue";
// import Stats from "../components/cruip/partials/Stats.vue";

//import Footer from "../components/cruip/partials/Footer.vue";

export default {
    name: "Home",
    components: {
        HeroHome,
        // FeaturesHome,
        FeaturesBlocks,
        // FeaturesWorld,
        // News,
        // FeaturesTable,
        PricingTables,
        Process,
        // Stats,
        // Cta
        //Footer,
    },
    metaInfo: {
        // Children can override the title.
        title: 'BotBro | Create Auto Checkout Bots',
        // Result: My Page Title ← My Site
        // If a child changes the title to "My Other Page Title",
        // it will become: My Other Page Title ← My Site
        //titleTemplate: '%s ← usedteslas.io ',
        // Define meta tags here.
        meta: [
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
            { name: 'description', content: 'Make auto checkout bots for Target, Walmart, and Amazon. Receive instant text and email notifications. Free Download.' },
            {
                link: [
                    { rel: 'canonical', href: 'https://www.botbro.io/' }
                ]
            },
            // Google / Schema.org markup:
            // { itemprop: 'name', content: 'botbro.io | Buy And Sell Used Teslas' },
            // { itemprop: 'description', content: 'Find a pre-owned Tesla to buy or sell your own. This is the fastest way to sell your Tesla.' },
            // { itemprop: 'image', content: 'https://www.usedteslas.io/img/white-tesla-snowy.904395c1.jpeg' }
        ]
    }
};
</script>