<template>
  <div>
    <div class="flex flex-col min-h-screen overflow-hidden">
      <!-- Site header -->
      <Header />
      <!-- Page content -->
      <main class="flex-grow">
        <!-- Contact section -->
        <section>
          <div class="max-w-6xl mx-auto px-4 sm:px-6">
            <div class="pt-32 pb-12 md:pt-40 md:pb-20">
              <!-- Section header -->
              <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 class="h1 text-black">
                  Have a question? Contact us directly.
                </h1>
              </div>

              <!-- Contact form -->
              <form class="max-w-xl mx-auto">
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label
                      class="block text-gray-800 text-sm font-medium mb-1"
                      for="first-name"
                      >First Name <span class="text-red-600">*</span></label
                    >
                    <input v-model="contactForm.firstName"
                      id="first-name"
                      type="text"
                      class="form-input w-full text-gray-800"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block text-gray-800 text-sm font-medium mb-1"
                      for="last-name"
                      >Last Name <span class="text-red-600">*</span></label
                    >
                    <input
                      v-model="contactForm.lastName"
                      id="last-name"
                      type="text"
                      class="form-input w-full text-gray-800"
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-800 text-sm font-medium mb-1"
                      for="email"
                      >Email <span class="text-red-600">*</span></label
                    >
                    <input
                      v-model="contactForm.email"
                      id="email"
                      type="email"
                      class="form-input w-full text-gray-800"
                      placeholder="Enter your email address"
                      required
                    />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-800 text-sm font-medium mb-1"
                      for="subject"
                      >Subject <span class="text-red-600">*</span></label
                    >
                    <input
                      v-model="contactForm.subject"
                      id="subject"
                      type="text"
                      class="form-input w-full text-gray-800"
                      placeholder="How can we help you?"
                      required
                    />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label
                      class="block text-gray-800 text-sm font-medium mb-1"
                      for="message"
                      >Message</label
                    >
                    <textarea v-model="contactForm.message"
                      id="message"
                      rows="4"
                      class="form-textarea w-full text-gray-800"
                      placeholder="Write your message"
                    ></textarea>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mt-4">
                  <div class="w-full px-3">
                    <button type="button" :disabled="isFormInvalid"
                      @click="sendMessage()"
                      class="btn text-white bg-primary hover:bg-secondary w-full"
                    >
                      Send
                    </button>
                  </div>
                </div>
                <!-- <div class="text-sm text-gray-600 mt-4">
                  By clicking "send" you consent to allow botbro.io to store and
                  process the personal information submitted above and agree to
                  our <a class="underline" href="">terms and conditions</a> as
                  well as our <a class="underline" href="">Privacy Policy</a>.
                </div> -->
              </form>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  metaInfo: {
    // Children can override the title.
    title: "Contact Us | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Feel free to reach out with any questions that you might have.",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/contact" }],
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Contact Us | BotBro" },
      {
        itemprop: "description",
        content:
          "Feel free to reach out with any questions that you might have.",
      },
      // {
      //   itemprop: "image",
      //   content:
      //     "https://www.bot.io/img/white-tesla-snowy.904395c1.jpeg",
      // },
    ],
  },
  components: {},
  data() {
    return {
      contactForm: {
        firstName: null,
        lastName: null,
        subject: null,
        email: null,
        message: null,
      },
    };
  },
  validations: {
    contactForm: {
      email: {
        required,
      },
      firstName: {
        required,
      },
      subject: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.contactForm.$invalid;
    },
  },
  methods: {
    sendMessage: function () {
      //window.fbq('track', 'ContactFormSubmitted');
      const params = [this.contactForm];
      this.$store
        .dispatch("auth/contactSendMessage", params)
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.contactForm.email = "";
          this.contactForm.firstName = "";
          this.contactForm.lastName = "";
          this.contactForm.subject = "";
          this.contactForm.message = "";
          this.$toasted.success("Message Sent!", { duration: 1000 });
          return new Promise(function (resolve) {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
#ContactContainer {
  padding: 10px;
  margin-top: 75px;
  margin-bottom: 40px;
  text-align: center; //padding: 10px;
}

#sendButtonContainer {
  text-align: left;
  padding: 0px;
  margin: 0px;
}

#contact-seller {
  margin: 0px;
  width: 100%;
}

.inputContainer {
  padding-bottom: 10px;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: left;
}

.container {
  text-align: center;
}

.card-meetup-link {
  color: black;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.card {
  margin: 10px;
  margin-top: 10px; //padding: 10px;
  padding-bottom: 70px;
}
</style>