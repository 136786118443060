<template>
  <section class="relative">
    <!-- Section background (needs .relative class on parent and next sibling elements) -->
    <div
      class="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none"
      aria-hidden="true"
    ></div>
    <div
      class="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"
    ></div>

    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">
        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 mb-4 text-black">Features</h2>
          <p class="text-xl text-gray-600">
            BotBro enables you to create custom bots with a click of a button.
          </p>
        </div>

        <!-- Items -->
        <div
          class="max-w-sm mx-auto grid gap-4 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none"
        >
          <!-- 1st item -->
          <div
            class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
          >
            <div class="iconContainer">
              <i class="fas fa-robot icon"></i>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">
              Auto Checkout
            </h4>
            <p class="text-gray-600 text-center">
              On supported sites our bots can monitor a product until it is restocked and then complete the checkout automatically. (Walmart, Target, Amazon)
            </p>
          </div>

          <!-- 2nd item -->
          <div
            class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
          >
            <div class="iconContainer">
              <i class="fas fa-envelope icon"></i>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">
              Restock Alerts
            </h4>
            <p class="text-gray-600 text-center">
              Monitor any product page and be the first to know when an item is back in stock.<br><br>
            </p>
          </div>


          <!-- 3rd item -->
          <div
            class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
          >
            <div class="iconContainer">
              <i class="fas fa-mobile-alt icon"></i>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">
              Notifications
            </h4>
            <p class="text-gray-600 text-center">
              Receive instant SMS and Email notifications when your bot buys a product or when the item is restocked.
            </p>
          </div>

          <!-- 4th item -->
          <div
            class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
          >
            <div class="iconContainer">
              <i class="fas fa-wrench icon"></i>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight text-black mb-1">
              New Features
            </h4>
            <p class="text-gray-600 text-center">
              We are actively developing new and improved features that will be available at no extra cost.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturesBlocks",
};
</script>
<style lang="scss">
.iconContainer {
  background-color: #2acb98;
  text-align: center;
  //padding: 10px;
  margin: 10px;
  border-radius: 50%;
  width:60px;
  height:60px;
}

.icon {
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  position:relative;
  top: calc(50% - 12px); /* 50% - 3/4 of icon height */
}
</style>