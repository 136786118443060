<template>
  <div>
    <Header />
    <div class="flex flex-col min-h-screen overflow-hidden">
      <main class="flex-grow">
        <section>
          <div class="max-w-6xl mx-auto px-4 sm:px-6">
            <div class="pt-32 pb-12 md:pt-40 md:pb-20">
              <div class="max-w-3xl mx-auto text-center pb-12">
                  <h1 class="h1 text-black" v-if="user.isSubscribed === false">Get Started</h1>
                  <h1 class="h1 text-black" v-if="user.isSubscribed != false">Manage Subscription</h1>
                <h1 class="h4 px-2">
                  <!-- Manage Account -->
                </h1>
                <div class="" v-if="user.isSubscribed === false">
                  <div class="mx-auto max-w-3xl mx-auto text-center  px-4 sm:px-6">
                    <p class="my-4 text-xl">$15 a month for unlimited access, cancel at any time.</p>
                    <button class="button is-primary is-medium is-hoverable" @click="createCheckoutSession()">
                      Buy Now <i class="fa fa-shopping-cart"></i>
                    </button>
                  </div>
                </div>
                <div class="" v-if="user.isSubscribed != false">
                  <div class="mx-auto max-w-3xl mx-auto text-center  px-4 sm:px-6 py-4">
                    <p class="my-4 text-xl">Update payment details or pause/cancel your subscription.</p>
                    <button class="button is-primary is-medium is-hoverable" @click="createCustomerPortalSession()">
                      Manage Subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
let stripe;
const stripeJs = document.createElement('script');
 stripeJs.src = 'https://js.stripe.com/v3/';
 stripeJs.async = true;
 stripeJs.onload = () => { setTimeout(() => {
        // stripe = window.Stripe('pk_test_51IvT0KC55A3G2XJrmYeUfz4msyEhksrB4LHP6Wp3neOtrPkT1jmMhhayIngJXpCjN0UHMKy0qNsBsHsW9Ft3K4Zr00KQmaR72Y');
        stripe = window.Stripe("pk_live_51IvT0KC55A3G2XJrnVrmed1DqlywPRC7rWjnZvPSfLCEs1LzuZ4zEd9IicCwwAtbWLEWLnsS764HXLe5VpMrFrik002DHWBZJx");
    }, 500);
 }
document.body && document.body.appendChild(stripeJs);
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    // Children can override the title.
    title: "Account | BotBro",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    //titleTemplate: '%s ← usedteslas.io ',
    // Define meta tags here.
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content: "Manage your account and subscription",
      },
      {
        link: [{ rel: "canonical", href: "https://www.botbro.io/account" }],
      },
      // Google / Schema.org markup:
      {
        itemprop: "name",
        content: "Account | BotBro",
      },
      {
        itemprop: "description",
        content: "Manage your account and subscription.",
      },
    ],
  },
  components: {},

  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      stripe: (state) => state.stats.stripe,
    }),
    ...mapGetters("stats", ["isLoading"]),
  },
  created() {
    this.$store.dispatch("stats/fetchUserStats");
  },
  methods: {
    onboardUser() {
      let connectButton = document.getElementById("stripeConnectButton");
      connectButton.setAttribute("disabled", "disabled");
      //connectButton.textContent = "Opening...";
      this.$store
        .dispatch("auth/onboardUser")
        .then((response) => response)
        .then((data) => {
          if (data.url) {
            window.location = data.url;
          } else {
            connectButton.removeAttribute("disabled");
            connectButton.textContent = "<Something went wrong>";
          }
        });
    },

    getJoinedDate() {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const createdAtDate = new Date(this.user.createdAt);
      return (
        monthNames[createdAtDate.getMonth()] + " " + createdAtDate.getFullYear()
      );
    },

    getOrderDate(dateString) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(dateString);
      return (
        monthNames[date.getMonth()] +
        " " +
        date.getDay() +
        ", " +
        date.getFullYear()
      );
    },

    checkStripeStats() {
      document.getElementById("stripeStatsContainer").innerText = JSON.stringify(this.stripe.account);
    },

    createCustomerPortalSession() {
      //this.isOpen = true;
      this.$store
        .dispatch("auth/createCustomerPortalSession")
        .then(function (response) {
          //console.log(response)
          window.location = response.url;
        })
        .catch(function (error) {
          console.error("Error:", error);
        });
    },

    createCheckoutSession() {
      this.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/E-IGCIPssPkCEKGZj5MD'})
      this.$gtag.event(['BeginCheckout'], {
        'event_category': 'ecommerce',
      })
      this.$store
        .dispatch("auth/createCheckoutSession", { referral: this.$referral })
        .then(function (response) {
          stripe.redirectToCheckout({
            sessionId: response.id
          })
        })
        .catch(function (error) {
          console.error("Error:", error);
      });
    }
  },
};
</script>

<style scoped lang="scss">
.info {
  margin-bottom: 70px;
}

.connectToStripeTitle {
  font-size: 22px;
  font-weight: 700;
}

.connectToStripeSubtitle {
  font-size: 16px;
  font-weight: 300;
}

#recentOrdersContainer {
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.order {
  // height: 150px;
  border-radius: 5px;
}

.recentOrdersText {
  //color: #82909B;
  //font-size: 22px;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
}

#name {
  font-weight: bold;
  font-size: 20px;
}

#balance {
  font-weight: bold;
  font-size: 20px;
}

#availableBalance {
  color: #82909b;
}

#balanceInfo {
  float: right;
  text-align: left;
}

#userInfo {
  float: left;
  text-align: left;
}

.orderBuyerInfo {
  float: left;
  text-align: left;
  width: 60%;
  overflow: scroll;
}

.orderPriceInfo {
  float: right;
  text-align: right;
  width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.orderName {
  font-weight: 600;
  font-size: 15px;
}

.orderEmail {
  font-weight: 500;
  color: #82909b;
  font-size: 15px;
}

.orderPrice {
  font-weight: 600;
  font-size: 15px;
  color: #2acb98;
}

.orderDate {
  color: #82909b;
  font-size: 15px;
}

.orderPlanName {
  font-weight: 500;
  font-size: 15px;
}

.dashboardLink {
  color: #2acb98;
  transition: 0.3s;
  font-weight: 500;
  &:hover {
    color: #456990; // text-decoration: underline;
  }
}

.title {
  text-align: center;
}

.subtitle {
  text-align: left;
}

.container {
  text-align: center;
}

.card-meetup-link {
  color: black;
}

.is-hoverable {
  transition: 0.3s;
  &:hover {
    -webkit-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    -moz-box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
    box-shadow: 2px 4px 15px -2px rgba(189, 189, 189, 1);
  }
}

.card {
  margin: 10px; //padding: 10px;
  padding-bottom: 70px;
}

#DashboardContainer {
  margin-top: 75px;
  margin-bottom: 40px;
  text-align: center; //padding: 10px;
}

#stripeStatsContainer {
  padding: 10px;
  background-color: lightgrey;
  margin: 10px;
  overflow: wrap;
}
</style>
